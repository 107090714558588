import React, { useState } from "react";
import { Game } from "../interfaces";
import { ensure, formatISODate } from "../utils";
import { Link } from "react-router-dom";
import { RosterUrl, ScoresheetUrl } from "../routes";
import _ from "lodash";
import { AttendanceButtons } from "./AttendanceButtons";
import { CompactTable } from "./CompactTable";
import { useEnsuredUser } from "../contexts";
import { Tag, Tooltip } from "antd";
import {ColumnType} from "antd/lib/table";

interface ButtonState {
  gameId?: number;
  modifyType?: string;
}

export function AttendanceTable(props: {
  games?: Game[];
  isLoading: boolean;
  showAttendanceButtons: boolean;
  onClick: (row: Game, modifyType: string) => void;
  showSubIndicator?: boolean;
  isPastGames?: boolean;
  attendanceColLabel?: string;
}) {
  const [buttonState, setButtonState] = useState<ButtonState>({});
  const user = useEnsuredUser();

  const onClickAttendanceButton = (row: Game, modifyType: string) => {
    setButtonState({ gameId: row.id, modifyType });
    props.onClick(row, modifyType);
  };
  const renderTeam = (team: "1" | "2", teamName: string, row: Game) => {
    const gameId: number = row.id;
    const teamId: number = team === "1" ? row.team1Id : row.team2Id;
    const fontWeight =
      row.playerAttendanceTeamId === teamId ? "bold" : "normal";

    const rosterLink = (
      <Link
        style={{ fontWeight: fontWeight }}
        to={RosterUrl.createUrl(_.toString(gameId), _.toString(teamId))}
      >
        {teamName}
      </Link>
    );
    const showSubIndicator =
      props.showSubIndicator &&
      user.teamId !== teamId &&
      row.playerAttendanceTeamId === teamId;

    return showSubIndicator ? (
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {rosterLink}
        <Tooltip title={"Substitute"}>
          <Tag color={"cyan"} style={{ marginLeft: 3 }}>
            S
          </Tag>
        </Tooltip>
      </div>
    ) : (
      rosterLink
    );
  };
  const renderAttendance = (row: Game) => {
    const inButtonLoading =
      props.isLoading &&
      buttonState.gameId === row.id &&
      buttonState.modifyType === "add";
    const outButtonLoading =
      props.isLoading &&
      buttonState.gameId === row.id &&
      buttonState.modifyType === "remove";
    return (
      <AttendanceButtons
        attendance={ensure(row.playerAttendance)}
        onClick={(modifyType: string) =>
          onClickAttendanceButton(row, modifyType)
        }
        disabled={inButtonLoading || outButtonLoading}
        inButtonLoading={inButtonLoading}
        outButtonLoading={outButtonLoading}
      />
    );
  };
  const gameColumns: ColumnType<Game>[] = [
    { dataIndex: "rinkName", title: "Rink" },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => formatISODate(val)
    },
    {
      dataIndex: "team1Name",
      title: "Home",
      render: (text: string, row: Game) => renderTeam("1", text, row)
    },
    {
      dataIndex: "team2Name",
      title: "Away",
      render: (text: string, row: Game) => renderTeam("2", text, row)
    }
  ];
  if (props.showAttendanceButtons) {
    gameColumns.push({
      dataIndex: "playerAttendance",
      title: props.attendanceColLabel || "Attendance",
      render: (text: string, row: Game) => renderAttendance(row)
    });
  }
  if (props.isPastGames) {
    gameColumns.push({
      key: "win/loss",
      title: "W/L",
      render: (text: string, row: Game) =>
        !row.winningTeamId
          ? "T"
          : row.winningTeamId === row.playerAttendanceTeamId
          ? "W"
          : "L"
    });
    gameColumns.push({
      key: "stats",
      title: "Stats",
      render: (text: string, row: Game) => (
        <Link to={ScoresheetUrl.createUrl(_.toString(row.id))}>Stats</Link>
      )
    });
  }
  return (
    <CompactTable
      data={props.games || []}
      columns={gameColumns}
      loading={!props.games}
    />
  );
}
