import React from "react";
import { Layout, Menu } from "antd";
import { HOME } from "../common/routes";
import {Route, Routes, useNavigate} from "react-router";
import { NotFound } from "../common/pages/NotFound";
import {
  REGISTRATION_LIST,
  NEW_SEASON,
  DIVISION_PLACEMENT,
  PersonDashboardUrl,
  MERGE_PLAYERS,
  GAMES,
  TEAMS,
  ScrimmageRosterUrl,
  EDIT_SEASONS
} from "./routes";
import { RegistrationList } from "./pages/RegistrationList";
import { SeasonSetUp } from "./pages/SeasonSetUp";
import { DivisionPlacement } from "./pages/DivisionPlacement";
import { AdminDashboard } from "./pages/AdminDashboard";
import { PersonDashboard } from "./pages/PersonDashboard";
import { MergePlayers } from "./pages/MergePlayers";
import { GamesDashboard } from "./pages/GamesDashboard";
import { TeamsDashboard } from "./pages/TeamsDashboard";
import { ScrimmageRoster } from "./pages/ScrimmageRoster";
import { Seasons } from "./pages/Seasons";

export function AdminApp() {
  let navigate = useNavigate();
  return (
    <Layout style={{ background: "white" }}>
      <Layout.Sider width={150}>
        <Menu mode={"inline"} theme={"dark"}>
          <Menu.Item
            key={"home"}
            onClick={() => navigate(HOME)}
          >
            Home
          </Menu.Item>
          <Menu.Item
            key={"merge"}
            onClick={() => navigate(MERGE_PLAYERS)}
          >
            Merge Players
          </Menu.Item>
          <Menu.Item
            key={"registrations"}
            onClick={() => navigate(REGISTRATION_LIST)}
          >
            Registrations
          </Menu.Item>
          <Menu.Item
            key={"new_season"}
            onClick={() => navigate(NEW_SEASON)}
          >
            Season Set Up
          </Menu.Item>
          <Menu.Item
            key={"edit_seasons"}
            onClick={() => navigate(EDIT_SEASONS)}
          >
            Seasons
          </Menu.Item>
          <Menu.Item
            key={"division_placement"}
            onClick={() => navigate(DIVISION_PLACEMENT)}
          >
            Divisions
          </Menu.Item>
          <Menu.Item
            key={"games"}
            onClick={() => navigate(GAMES)}
          >
            Games
          </Menu.Item>
          <Menu.Item
            key={"teams"}
            onClick={() => navigate(TEAMS)}
          >
            Teams
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content style={{ paddingLeft: 10 }}>
        <Routes>
          <Route path={HOME} element={<AdminDashboard />} />
          <Route
            path={REGISTRATION_LIST}
            element={<RegistrationList />}
          />
          <Route
            path={NEW_SEASON}
            element={<SeasonSetUp />}
          />
          <Route path={EDIT_SEASONS} element={<Seasons />} />
          <Route
            path={DIVISION_PLACEMENT}
            element={<DivisionPlacement />}
          />
          <Route
            path={PersonDashboardUrl.pattern}
            element={<PersonDashboard />}
          />
          <Route
            path={MERGE_PLAYERS}
            element={<MergePlayers />}
          />
          <Route path={GAMES} element={<GamesDashboard />} />
          <Route
            path={ScrimmageRosterUrl.pattern}
            element={<ScrimmageRoster />}
          />
          <Route path={TEAMS} element={<TeamsDashboard />} />
          <Route element={<NotFound />} />
        </Routes>
      </Layout.Content>
    </Layout>
  );
}
