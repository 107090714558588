import React, { useState } from "react";
import _ from "lodash";
import { formatISODate, getDivisionName } from "../../common/utils";
import { ScrimmagePlayer, Player, Scrimmage } from "../../common/interfaces";
import {
  RosterAttendanceTable,
  TagProps
} from "../../common/components/RosterAttendanceTable";
import { useGet, usePost } from "../../common/hooks";
import { Button, Divider, Select } from "antd";
import { CalendarOutlined, EnvironmentOutlined, MailOutlined, TeamOutlined } from "@ant-design/icons";
import { TextWithIconPrefix } from "../../common/components/TextWithIconPrefix";
import Spinner from "../../common/components/Spinner";
import { TeamEmailButton } from "../../common/components/TeamEmailForm";
import {useParams} from "react-router";

export function getPersonDisplay<T extends ScrimmagePlayer>(p: T): string {
  return `(${getDivisionName(p.division)[0]}) ${p.firstName} ${p.lastName} (${
    p.previousGamesCount
  } prev games)`;
}

export function ScrimmageHeader(props: { scrimmage: Scrimmage }) {
  return (
    <>
      <TextWithIconPrefix icon={<TeamOutlined />} text={"Scrimmage"} />
      <TextWithIconPrefix
        text={formatISODate(props.scrimmage.date)}
        icon={<CalendarOutlined />}
      />
      <TextWithIconPrefix
        text={props.scrimmage.rinkName}
        icon={<EnvironmentOutlined />}
      />
    </>
  );
}

export function ScrimmageRoster() {
  const { scrimmageId } = useParams();
  const scrimmageInfo = useGet<Scrimmage>(
    `/api/scrimmages?scrimmageId=${scrimmageId}`
  );
  const scrimmagePlayers = useGet<ScrimmagePlayer[]>(
    `/api/scrimmageroster?scrimmageId=${scrimmageId}`
  );
  const availabilities = useGet<ScrimmagePlayer[]>(
    `/api/getScrimmageAvailabilities?scrimmageId=${scrimmageId}`
  );
  const attendancePostReq = usePost({
    uri: "/api/modifyScrimmageAttendance",
    deps: [scrimmagePlayers]
  });
  const assignPlayersPostReq = usePost({
    uri: "/api/assignScrimmage",
    deps: [scrimmagePlayers]
  });

  const partitionRosters = (
    scrimmages: ScrimmagePlayer[]
  ): { homePlayers: ScrimmagePlayer[]; awayPlayers: ScrimmagePlayer[] } => {
    const [homePlayers, awayPlayers] = _.partition(
      scrimmages,
      p => p.onHomeTeam
    );
    return {
      homePlayers: _.sortBy(homePlayers, ["division", "lastName", "firstName"]),
      awayPlayers: _.sortBy(awayPlayers, ["division", "lastName", "firstName"])
    };
  };

  const partition = partitionRosters(scrimmagePlayers.data || []);

  const onModifyAttendanceHomeTeam = (
    row: ScrimmagePlayer,
    modifyType: string
  ) => {
    attendancePostReq.send({
      scrimmageId,
      personId: row.id,
      modifyType,
      isHomeTeam: true
    });
  };
  const onModifyAttendanceAwayTeam = (
    row: ScrimmagePlayer,
    modifyType: string
  ) => {
    attendancePostReq.send({
      scrimmageId,
      personId: row.id,
      modifyType,
      isHomeTeam: false
    });
  };
  const toTags = (row: ScrimmagePlayer) => {
    const tags: TagProps[] = [];
    if (row.inviteSent) {
      tags.push({ tag: <MailOutlined />, color: "purple" });
    }
    return tags;
  };

  if (!scrimmageInfo.data) {
    return <Spinner size={"large"} />;
  }
  const homePlayerList = _.join(
    partition.homePlayers.map(p => `${p.firstName} ${p.lastName}`),
    "\n"
  );
  const awayPlayerList = _.join(
    partition.awayPlayers.map(p => `${p.firstName} ${p.lastName}`),
    "\n"
  );
  const emailBody =
    `You've been selected to play in a scrimmage on ${formatISODate(
      scrimmageInfo.data.date,
      "MMM dd"
    )} at ${formatISODate(
      scrimmageInfo.data.date,
      "h:mma"
    )}. Home team will wear white, and away team will wear dark.\n\n` +
    "REMINDER: Please pay $25 to ncwhl.main@gmail.com via PayPal PRIOR to your scrimmage, once you have a roster spot!" +
    "Please select the “Family & Friends” option (not “Goods and Services”) and under Notes, " +
    "enter your assigned date and time.\n\n" +
    `The teams are:\n\nHome:\n${homePlayerList}\n\nAway:\n${awayPlayerList}`;

  return (
    <>
      <ScrimmageHeader scrimmage={scrimmageInfo.data} />
      <div
        style={{
          display: "flex",
          justifyContent: "left"
        }}
      >
        <TeamEmailButton
          scrimmageResponse={scrimmageInfo}
          playersResponse={scrimmagePlayers}
          defaultSubject={"Scrimmage"}
          defaultBody={emailBody}
          buttonLabel={"Email Players"}
        />
        <Button
          type={"primary"}
          onClick={() =>
            assignPlayersPostReq.send({ scrimmageId: scrimmageId })
          }
          loading={assignPlayersPostReq.isLoading}
        >
          Randomly Assign Players
        </Button>
      </div>
      <Divider>Invites</Divider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginBottom: 10
          }}
        >
          <PlayerDropdown
            scrimmageId={scrimmageId!}
            isHomeTeam={true}
            onSuccess={() => scrimmagePlayers.reload()}
          />
          <RosterAttendanceTable
            data={partition.homePlayers}
            disableButtons={false}
            requestLoading={attendancePostReq.isLoading}
            dataLoading={!scrimmagePlayers.data}
            modifyAttendance={onModifyAttendanceHomeTeam}
            columnLabel={"Player"}
            getEntityDisplay={getPersonDisplay}
            toTags={toTags}
            showDeleteButton={_ => true}
            deleteButtonModifyType={"trash"}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginBottom: 10
          }}
        >
          <PlayerDropdown
            scrimmageId={scrimmageId!}
            isHomeTeam={false}
            onSuccess={() => scrimmagePlayers.reload()}
          />
          <RosterAttendanceTable
            data={partition.awayPlayers}
            disableButtons={false}
            requestLoading={attendancePostReq.isLoading}
            dataLoading={!scrimmagePlayers.data}
            modifyAttendance={onModifyAttendanceAwayTeam}
            columnLabel={"Player"}
            getEntityDisplay={getPersonDisplay}
            toTags={toTags}
            showDeleteButton={_ => true}
            deleteButtonModifyType={"trash"}
          />
        </div>
      </div>
      <Divider>Availabilities</Divider>
      <RosterAttendanceTable
        data={_.sortBy(
          availabilities.data || [],
          "attendance",
          "division",
          "previousGamesCount",
          "lastName"
        )}
        disableButtons={true}
        requestLoading={false}
        dataLoading={!availabilities.data}
        modifyAttendance={function(_, __) {}}
        columnLabel={"Player"}
        getEntityDisplay={getPersonDisplay}
        showDeleteButton={_ => false}
      />
    </>
  );
}

export function sortPlayersByDivisionAndLastName<T extends Player>(
  players: T[]
): T[] {
  return _.orderBy(players, ["division", "lastName"], ["asc", "asc"]);
}

export function getPlayerDisplay<T extends Player>(p: T): string {
  return `(${getDivisionName(p.division)[0]}) ${p.firstName} ${p.lastName}`;
}

function PlayerDropdown(props: {
  scrimmageId: string;
  isHomeTeam: boolean;
  onSuccess: () => void;
}) {
  const { scrimmageId, isHomeTeam, onSuccess } = props;
  const players = useGet<Player[]>(`/api/activePlayers`);
  const [player, setPlayer] = useState<number | undefined>(undefined);
  const postReq = usePost({
    uri: "/api/modifyScrimmageAttendance",
    deps: [players]
  });

  const onSave = () => {
    postReq.send(
      { scrimmageId, isHomeTeam, personId: player, modifyType: "add" },
      onSuccess
    );
    setPlayer(undefined);
  };

  const selectOpts = sortPlayersByDivisionAndLastName(players.data || []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: 10
      }}
    >
      <Select
        showSearch={true}
        onChange={(val: number) => setPlayer(val)}
        value={player}
        style={{ width: 320 }}
        placeholder={"Add a player"}
        loading={players.isLoading}
        optionFilterProp={"children"}
      >
        {selectOpts.map((player: Player) => (
          <Select.Option key={player.id} value={player.id}>
            {getPlayerDisplay(player)}
          </Select.Option>
        ))}
      </Select>{" "}
      <Button
        style={{ marginLeft: 5 }}
        icon={"save"}
        type={"primary"}
        onClick={onSave}
        disabled={player === undefined}
        loading={postReq.isLoading || players.isLoading}
      />
    </div>
  );
}
