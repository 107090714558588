import React from "react";
import { Button, Form, Input } from "antd";
import { usePost } from "../hooks";
import { useUserContext } from "../contexts";
import { User } from "../interfaces";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";

interface LoginValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const postReq = usePost({ uri: "/api/user", asForm: true });
  const { setUser } = useUserContext();

  const onSuccess = (data: User) => {
    setUser(data);
    navigate("/");
  };

  const onSubmit = (values: LoginValues) => {
    form.validateFields()
        .then((validValues: LoginValues) => {
          postReq.send(validValues, onSuccess);
        });
  };

  return (
    <Form layout={"inline"} onFinish={onSubmit} form={form}>
      <Form.Item
          name="username"
          rules={[{ required: true, message: 'Username is required.' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password is required.' }]}
      >
        <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={postReq.isLoading}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}
