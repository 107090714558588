import React from "react";
import { useGet } from "../../common/hooks";
import { Goal } from "../../common/interfaces";
import { getGoalsTableData } from "../../common/utils";
import { CompactTable } from "../../common/components/CompactTable";
import Spinner from "../../common/components/Spinner";

export function GoalsTable(props: { gameId: number; teamId: number }) {
  const { gameId, teamId } = props;
  const goals = useGet<Goal[]>(`/api/goals?gameId=${gameId}&teamId=${teamId}`);
  const shots = useGet<{ shots: number }>(
    `/api/shots?gameId=${gameId}&teamId=${teamId}`
  );
  const { data, columns } = getGoalsTableData(goals.data);

  return (
    <div>
      <div style={{ padding: 5 }}>
        <strong>Shots on goal:</strong>{" "}
        {shots.data ? shots.data.shots : <Spinner size={"small"} />}
      </div>
      <CompactTable
        data={data}
        columns={columns}
        loading={goals.isLoading}
        emptyText={"No Goals"}
      />
    </div>
  );
}
