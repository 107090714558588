import { Button, message, Upload } from "antd";
import Icon from '@ant-design/icons';
import React from "react";

export function FileUpload(props: {
  successMessage?: string;
  onSuccess?: () => void;
  failureMessage?: string;
  apiPath: string;
  uploadLabel: string;
}) {
  const {
    successMessage,
    onSuccess,
    failureMessage,
    apiPath,
    uploadLabel
  } = props;

  const uploadProps = {
    name: "file",
    action: apiPath,
    headers: {
      authorization: "authorization-text"
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        message.success(successMessage || "Successfully uploaded file.");
        if (onSuccess) onSuccess();
      } else if (info.file.status === "error") {
        message.error(failureMessage || "Failed to upload file.");
      }
    }
  };

  return (
    <Upload {...uploadProps}>
      <Button style={{ margin: 10 }}>
        <Icon type="upload" /> {uploadLabel}
      </Button>
    </Upload>
  );
}
