import React from "react";
import Table from "antd/lib/table";

export function CompactTable(props: {
  data: object[];
  columns: object[];
  loading?: boolean;
  emptyText?: string;
  rowKey?: string;
  hideHeader?: boolean;
  onChange?: (filters: any) => void;
}) {
  return (
    <Table
      dataSource={props.data}
      columns={props.columns}
      pagination={false}
      loading={props.loading}
      size={"small"}
      locale={{ emptyText: props.emptyText || "No Data" }}
      rowKey={props.rowKey || "id"}
      scroll={{ x: true }}
      showHeader={!props.hideHeader}
      onChange={(pagination: any, filters: any) => {
        if (props.onChange) {
          props.onChange(filters);
        }
      }}
    />
  );
}
