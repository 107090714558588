import { Col, Divider, Radio, Row, Spin } from "antd";
import React from "react";
import _ from "lodash";
import { useGet, usePost } from "../../common/hooks";
import { Division, Player } from "../../common/interfaces";
import { Link } from "react-router-dom";
import { PersonDashboardUrl } from "../routes";

function sortByDivisionThenName(p1: Player, p2: Player) {
  if (p1.division === p2.division) {
    return p1.lastName.localeCompare(p2.lastName);
  }
  return p1.division - p2.division;
}

const allDivisions = [
  Division.UNKNOWN,
  Division.GREEN,
  Division.RED,
  Division.MAROON,
  Division.BLUE
];
const divisionColors = new Map([
  [Division.GREEN, "darkseagreen"],
  [Division.RED, "indianred"],
  [Division.MAROON, "maroon"],
  [Division.BLUE, "steelblue"]
]);

export function DivisionPlacement() {
  const people = useGet<Player[]>("/api/getPlayers");
  const updateDivision = usePost({
    uri: "/api/setDivision",
    deps: [people]
  });

  const activePeople =
    (people.data &&
      people.data
        .filter(p => p.active)
        .sort((p1: Player, p2: Player) =>
          p1.lastName.localeCompare(p2.lastName)
        )) ||
    [];
  const activePeopleWithoutTeam = activePeople
    .filter(p => !p.onTeam)
    .sort(sortByDivisionThenName);
  const activePeopleWithTeam = activePeople.filter(p => p.onTeam);

  function displayDivisionButtons(player: Player) {
    return (
      <div key={player.id}>
        <Link to={PersonDashboardUrl.createUrl(player.id)}>
          {player.firstName} {player.lastName}
        </Link>
        <Radio.Group
          key={player.id}
          defaultValue={Division[player.division]}
          buttonStyle="solid"
          style={{ margin: 10 }}
        >
          {allDivisions.map((division: Division) => {
            return (
              <Radio.Button
                value={Division[division]}
                key={division}
                onClick={() =>
                  updateDivision.send({
                    personId: player.id,
                    divisionId: division
                  })
                }
              >
                {_.capitalize(Division[division])}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  let peopleByDivision = new Map<Division, Player[]>();
  allDivisions.forEach(division => {
    peopleByDivision.set(
      division,
      activePeopleWithTeam.filter(p => p.division === division)
    );
  });

  return (
    <>
      <Divider>Players needing division placement</Divider>
      {!people.data ? (
        <Spin />
      ) : (
        activePeopleWithoutTeam
          .filter(p => p.division === Division.UNKNOWN)
          .map(displayDivisionButtons)
      )}

      <Divider>Players not on a team yet</Divider>
      {!people.data ? (
        <Spin />
      ) : (
        activePeopleWithoutTeam
          .filter(p => p.division !== Division.UNKNOWN)
          .map(displayDivisionButtons)
      )}

      <Divider>Players already on a team</Divider>
      <Row gutter={16}>
        {allDivisions
          .filter(d => d !== Division.UNKNOWN)
          .map((division: Division) => {
            return (
              <Col className="gutter-row" span={6} key={division}>
                <div
                  className="gutter-box"
                  key={division}
                  style={{
                    background: divisionColors.get(division),
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {`${_.capitalize(Division[division])} (${
                    (peopleByDivision.get(division) || []).length
                  } players)`}
                </div>
                {!people.data ? (
                  <Spin />
                ) : (
                  (peopleByDivision.get(division) || []).map(
                    (player: Player) => {
                      return (
                        <div key={player.id}>
                          <Link
                            to={PersonDashboardUrl.createUrl(player.id)}
                          >
                            {player.firstName} {player.lastName}
                          </Link>
                        </div>
                      );
                    }
                  )
                )}
              </Col>
            );
          })}
      </Row>
    </>
  );
}
