import React, { useState } from "react";
import { useGet, usePost } from "../../common/hooks";
import { Button, Form, Modal, InputNumber } from "antd";
import { getGoalsTableData } from "../../common/utils";
import { TableWithDeleteButtons } from "./TableWithDeleteButtons";
import { PlayerSelect } from "./PlayerSelect";
import { Goal, PostRequest } from "../../common/interfaces";
import { AddButton } from "./AddButton";
import { PeriodSelect } from "../../common/components/PeriodSelect";

export function GoalsEditor(props: { gameId: number; teamId: number }) {
  const { gameId, teamId } = props;

  const goals = useGet<Goal[]>(`/api/goals?gameId=${gameId}&teamId=${teamId}`);
  const shots = useGet<{ shots: number }>(
    `/api/shots?gameId=${gameId}&teamId=${teamId}`
  );
  const deletePostReq = usePost({
    uri: "/api/deleteGoal",
    deps: [goals, shots]
  });
  const createPostReq = usePost({
    uri: "/api/createGoal",
    deps: [goals, shots]
  });
  const shotsPostReq = usePost({ uri: "/api/shots", deps: [shots] });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hideModal = () => setModalVisible(false);

  const { data, columns } = getGoalsTableData(goals.data);

  return (
    <>
      <strong>Shots on goal: </strong>
      <InputNumber
        style={{ maxWidth: 300, marginBottom: 10 }}
        value={shots.data ? shots.data.shots : undefined}
        onChange={(num: number | null) =>
          shotsPostReq.send({ gameId, teamId, num })
        }
        min={0}
      />
      <TableWithDeleteButtons
        data={data}
        columns={columns}
        onDelete={(goal: Goal) =>
          deletePostReq.send({ gameId, goalId: goal.id })
        }
        emptyText={"No Goals"}
        loading={goals.isLoading}
        buttonLoading={deletePostReq.isLoading}
      />
      <AddButton text={"Add goal"} onClick={() => setModalVisible(true)} />
      <Modal visible={modalVisible} footer={null} onCancel={hideModal}>
        <GoalForm
          gameId={gameId}
          teamId={teamId}
          postReq={createPostReq}
          hideModal={hideModal}
        />
      </Modal>
    </>
  );
}

interface GoalFormProps {
  gameId: number;
  teamId: number;
  postReq: PostRequest;
  hideModal: () => void;
}

interface GoalFormValues {
  period: number;
  minute: number;
  second: number;
  goalPersonId: number;
  assist1PersonId: number | string;
  assist2PersonId: number | string;
}

function GoalForm(props: GoalFormProps) {
  const { gameId, teamId, postReq, hideModal } = props;
  const [form] = Form.useForm<GoalFormValues>();

  const onSuccess = () => {
    form.resetFields();
    hideModal();
  };

  const onSubmit = (values: GoalFormValues) => {
    form.validateFields()
        .then((validValues: GoalFormValues) => {
          postReq.send( {...validValues, teamId, gameId }, onSuccess);
        });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };


  const goalScorer = Form.useWatch('goalPersonId', form);
  const goalAssist1 = Form.useWatch('assist1PersonId', form);

  return (
    <Form onFinish={onSubmit} layout={"horizontal"} form={form}>
      <Form.Item
          label={"Period"}
          name={"period"}
          rules={[{ required: true }]}
          {...formItemLayout}>
          <PeriodSelect />
      </Form.Item>
      <Form.Item
          label={"Minute"}
          name={"minute"}
          rules={[{ required: true }]}
          {...formItemLayout}>
          <InputNumber min={0} max={15}></InputNumber>
      </Form.Item>
      <Form.Item
          label={"Second"}
          name={"second"}
          rules={[{ required: true }]}
          {...formItemLayout}>
          <InputNumber min={0} max={60}></InputNumber>
      </Form.Item>
      <Form.Item
          label={"Goal"}
          name={"goalPersonId"}
          rules={[{ required: true }]}
          {...formItemLayout}>
          <PlayerSelect
            gameId={gameId}
            teamId={teamId}
            excludePlayers={[
              form.getFieldValue('assist1PersonId'),
              form.getFieldValue('assist2PersonId')
            ]}></PlayerSelect>
      </Form.Item>
      <Form.Item
          label={"Assist 1"}
          name={"assist1PersonId"}
          {...formItemLayout}>
          <PlayerSelect
            gameId={gameId}
            teamId={teamId}
            excludePlayers={[
                form.getFieldValue('goalPersonId'),
                form.getFieldValue('assist2PersonId')
            ]}
            includeEmpty={true}
            disabled={goalScorer === undefined}
          ></PlayerSelect>
      </Form.Item>
      <Form.Item
          label={"Assist 2"}
          name={"assist2PersonId"}
          {...formItemLayout}>
          <PlayerSelect
            gameId={gameId}
            teamId={teamId}
            excludePlayers={[
              form.getFieldValue('goalPersonId'),
              form.getFieldValue('assist1PersonId')
            ]}
            includeEmpty={true}
            disabled={goalAssist1 === undefined}
          ></PlayerSelect>
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={postReq.isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
