import React from "react";
import { Button } from "antd";
import { Attendance } from "../interfaces";
import {CheckOutlined, CloseOutlined, LoadingOutlined} from "@ant-design/icons";

export function AttendanceButtons(props: {
  attendance: Attendance;
  onClick: (modifyType: string) => void;
  disabled?: boolean;
  inButtonLoading?: boolean;
  outButtonLoading?: boolean;
}) {
  const {
    attendance,
    onClick,
    disabled,
    inButtonLoading,
    outButtonLoading
  } = props;
  const buttonStyle = { borderColor: "gray", color: "black" };
  const inButtonStyle = { ...buttonStyle, backgroundColor: "#b7eb8f" };
  const outButtonStyle = { ...buttonStyle, backgroundColor: "#ffa39e" };
  return (
    <div style={{ overflow: 'visible', width: '65px' }}>
      <Button
        style={attendance === Attendance.IN ? inButtonStyle : buttonStyle}
        onClick={() => onClick("add")}
        disabled={disabled}
        size={"small"}
      >
        {inButtonLoading ? <LoadingOutlined /> : <CheckOutlined />}
      </Button>{" "}
      <Button
        style={attendance === Attendance.OUT ? outButtonStyle : buttonStyle}
        onClick={() => onClick("remove")}
        disabled={disabled}
        size={"small"}
      >
        {outButtonLoading ? <LoadingOutlined /> : <CloseOutlined />}
      </Button>
    </div>
  );
}
