export const SCHEDULE = "/schedule";

export const PASSWORD_RESET = "/reset/:personId/:code";

export const SUBBING = "/subbing";

export class SubListUrl {
  public static pattern = SubListUrl.createUrl(":gameId", ":teamId");

  static createUrl(gameId: string, teamId: string): string {
    return `/games/${gameId}/subs/${teamId}`;
  }
}

export class StatsUrl {
  public static pattern = "/stats/:division?/:season?";

  static createUrl(): string {
    return `/stats`;
  }
}

export const SETTINGS = "/settings";

export const SCRIMMAGES = "/scrimmages";

export const DONATE = "/donate";

export const SEASON_REGISTRATION = "/register/season/:seasonId";
