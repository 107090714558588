import React from "react";
import { GamesList } from "./pages/GamesList";
import {Route, Routes} from "react-router";
import { Scoresheet } from "./pages/Scoresheet";
import { HOME, RosterUrl, ScoresheetUrl } from "../common/routes";
import { Roster } from "../common/pages/Roster";
import { NotFound } from "../common/pages/NotFound";

export function ScorekeepersApp() {
  return (
    <Routes>
      <Route path={HOME} element={<GamesList />} />
      <Route path={ScoresheetUrl.pattern} element={<Scoresheet />} />
      <Route
        path={RosterUrl.pattern}
        element={<Roster linkBackToScoresheet={true} />}
      />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
}
