import React from "react";
import { useGet } from "../../common/hooks";
import { Table } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Game } from "../../common/interfaces";
import { formatISODate, nDaysFromNow } from "../../common/utils";
import { ScoresheetUrl} from "../../common/routes";
import {ColumnType} from "antd/lib/table";

export function GamesList() {
  const games = useGet<Game[]>("/api/games");

  const sortAndFilterGames = (games: Game[]) => {
    const tomorrow = nDaysFromNow(1);
    games = games.filter(g => g.date <= tomorrow);
    return _.sortBy(games, g => g.date).reverse();
  };

  const renderScoresheet = (gameId: number) => {
    return (
      <Link to={ScoresheetUrl.createUrl(_.toString(gameId))}>
        Scoresheet
      </Link>
    );
  };

  const columns: ColumnType<Game>[] = [
    { dataIndex: "rinkName", title: "Rink" },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => formatISODate(val)
    },
    { dataIndex: "team1Name", title: "Home" },
    { dataIndex: "team2Name", title: "Away" },
    { dataIndex: "id", title: "Scoresheet", render: renderScoresheet }
  ];

  return (
    <Table
      dataSource={sortAndFilterGames((games.data || []) as Game[])}
      columns={columns}
      rowKey={"id"}
      loading={games.isLoading}
      scroll={{ x: true }}
    />
  );
}
