import { useGet, usePost } from "../../common/hooks";
import React, { ChangeEvent, useState } from "react";
import { Button, Input } from "antd";
import {Game} from "../../common/interfaces";
import { CompactTable } from "../../common/components/CompactTable";
import Title from "antd/lib/typography/Title";
import {EditOutlined, SaveOutlined} from "@ant-design/icons";

interface StaffRow {
  key: string;
  label: string;
  value: string;
}

export function GameStaffEditor(props: { gameId: string }) {
  const gameId = props.gameId;

  const game = useGet<Game>(`/api/games?gameId=${gameId}`);
  const [editing, setEditing] = useState<boolean>(false);
  const [staff, setStaff] = useState<{ [key: string]: string | undefined }>({});
  const postReq = usePost({ uri: "/api/game/staff", deps: [game] });

  const renderName = (text: string) => {
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  };
  const renderValue = (text: string, row: StaffRow) => {
    if (editing) {
      return (
        <Input
          value={staff[row.key]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setStaff({ ...staff, [row.key]: e.target.value })
          }
        />
      );
    } else {
      return text;
    }
  };

  const data: StaffRow[] = game.data
    ? [
        {
          key: "scorekeeper",
          label: "Scorekeeper",
          value: game.data.scorekeeper
        },
        { key: "referee1", label: "Referee 1", value: game.data.referee1 },
        { key: "referee2", label: "Referee 2", value: game.data.referee2 },
        { key: "comment", label: "Comment", value: game.data.comment }
      ]
    : [];
  const columns = [
    {
      dataIndex: 'label',
      key: 'label',
      render: renderName,
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: renderValue
    }
  ];

  return (
    <>
      <Title level={3}>{"Game Staff"}</Title>
      <CompactTable
        data={data}
        columns={columns}
        rowKey={"field"}
        hideHeader={true}
        loading={game.isLoading}
      />
      <Button
        style={{ marginTop: 10 }}
        type={editing ? "primary" : "default"}
        icon={editing ? <SaveOutlined /> : <EditOutlined />}
        loading={postReq.isLoading}
        onClick={() => {
          if (editing) {
            postReq.send({ gameId, ...staff }, () => setEditing(false));
          } else {
            if (game.data) {
              setStaff({
                scorekeeper: game.data.scorekeeper || undefined,
                referee1: game.data.referee1 || undefined,
                referee2: game.data.referee2 || undefined,
                comment: game.data.comment || undefined
              });
            }
            setEditing(true);
          }
        }}
      >
        {editing ? "Save" : "Edit"}
      </Button>
    </>
  );
}
