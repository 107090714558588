import React, { CSSProperties, useRef, useState } from "react";
import { Button, Divider, Input, Select } from "antd";

export function SelectWithCustomOption(props: {
  initOptions: string[];
  value?: string;
  onChange?: (val: string) => void;
  loading?: boolean;
  style?: CSSProperties;
}) {
  const [options, setOptions] = useState<string[] | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [customOpt, setCustomOpt] = useState<string>("");
  const lock = useRef<boolean>(false);

  const effectiveOptions = options || props.initOptions;

  const saveButton = (
    <Button
      icon={"plus"}
      size={"small"}
      disabled={!customOpt || effectiveOptions.includes(customOpt)}
      type={"primary"}
      onMouseDown={() => (lock.current = true)}
      onClick={() => {
        setOptions([...effectiveOptions, customOpt]);
        setCustomOpt("");
        lock.current = false;
        setOpen(false);
        props.onChange && props.onChange(customOpt);
      }}
    />
  );

  return (
    <Select
      open={open}
      value={props.value}
      onChange={props.onChange}
      loading={props.loading}
      style={props.style}
      showSearch={true}
      onDropdownVisibleChange={(visible: boolean) => {
        if (lock.current) {
          return;
        }
        setOpen(visible);
        if (!visible) {
          setCustomOpt("");
        }
      }}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: 0 }} />
          <Input
            onFocus={() => (lock.current = true)}
            onMouseLeave={() => (lock.current = false)}
            onBlur={() => {
              if (open && !lock.current) {
                setOpen(false);
              }
              lock.current = false;
            }}
            style={{ padding: 4 }}
            value={customOpt}
            onChange={e => setCustomOpt(e.target.value)}
            addonAfter={saveButton}
            placeholder={"Add custom..."}
          />
        </div>
      )}
    >
      {effectiveOptions.map(opt => (
        <Select.Option key={opt} value={opt}>
          {opt}
        </Select.Option>
      ))}
    </Select>
  );
}
