import React from "react";
import { Spin } from "antd";

export default function Spinner(props: {
  size: "small" | "default" | "large" | undefined;
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Spin size={props.size} />
    </div>
  );
}
