import React from "react";
import { usePost } from "../../common/hooks";
import { useUserContext } from "../../common/contexts";
import { LOGIN } from "../../common/routes";
import {Button, Form, Input} from "antd";
import {useNavigate} from "react-router";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";

interface RegistrationValues {
  email: string;
  username: string;
  password: string;
}

export function Registration() {
  const postReq = usePost({ uri: "/api/register", asForm: true });
  const { user } = useUserContext();
  const [form] = Form.useForm();
  let navigate = useNavigate();

  if (user) {
    navigate("/");
  }

  const onSubmit = (values: RegistrationValues) => {
    form.validateFields()
        .then((validValues: RegistrationValues) => {
          postReq.send( values,
              () => navigate("/"));
        });
  };

  const getValidateStatus = (fieldName: string): "error" | undefined => {
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName)
      ? "error"
      : undefined;
  };

  const validateUsername = (
    rule: any,
    value: string | null
  ) => {
    // Usernames must be all-lowercase and cannot contain any unicode since we store
    // usernames as cookies.
    if (value === null) {
      return Promise.resolve();
    }

    if (value.includes(" ")) {
      return Promise.reject(new Error("Username cannot contain spaces."));
    }

    for (let i = 0; i < value.length; i++) {
      if (value.charCodeAt(i) > 127) {
        return Promise.reject(new Error("Username must only consist of ASCII characters."));
      }
      const c = value.charAt(i);
      if (c !== c.toLowerCase()) {
        return Promise.reject(new Error("Username must be all lowercase."));
      }
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        layout={"vertical"}
        style={{ maxWidth: "500px" }}
      >
        <Form.Item
          label={"Email"}
          name={"email"}
          rules={[{ required: true, message: "Email is required." }]}
          validateStatus={getValidateStatus("email")}
          extra={
            "This should be the same email you used when registering for the season."
          }
        >
          <Input
              prefix={<MailOutlined className={"IconPrefix"} />}
              placeholder="Email address"
          />
        </Form.Item>
        <Form.Item
          label={"Username"}
          name={"username"}
          rules={[{ validator: validateUsername }, { required: true, message: "Username is required."}]}
        >
          <Input
              prefix={<UserOutlined className={"IconPrefix"} />}
              type="username"
              placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          label={"Password"}
          name={"password"}
          rules={[{ required: true, message: "Password is required." }]}
          validateStatus={getValidateStatus("password")}
        >
          <Input
              prefix={<LockOutlined className={"IconPrefix"} />}
              type="password"
              placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={postReq.isLoading}>
            Register
          </Button>
        </Form.Item>
      </Form>
      <div>
        Already have an account?
        <Button
          style={{ paddingLeft: 5 }}
          type={"link"}
          onClick={() => navigate(LOGIN)}
        >
          Login here
        </Button>
      </div>
    </>
  );
}
