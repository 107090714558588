import React from "react";
import { useEnsuredUser } from "../../common/contexts";
import { useGet, usePost } from "../../common/hooks";
import { Button, Checkbox, Divider, Form, Input, message } from "antd";
import _ from "lodash";
import { Game, SubbingPrefs } from "../../common/interfaces";
import { AttendanceTable } from "../../common/components/AttendanceTable";
import { updateAvailability } from "../../common/utils";
import Spinner from "../../common/components/Spinner";

export function Subbing() {
  const user = useEnsuredUser();
  const [form] = Form.useForm();
  const prefs = useGet<SubbingPrefs | null>(
    `/api/subbing?personId=${user.personId}`
  );
  const availabilities = useGet<Game[]>(
    `/api/subbingAvailabilities?personId=${user.personId}`
  );
  const postReq = usePost({
    uri: "/api/updateSubbingPreferences",
    deps: [prefs]
  });
  const availabilityPostReq = usePost({
    uri: "/api/updateSubbingAvailability",
    deps: [availabilities]
  });

  if (prefs.data === undefined || availabilities.data === undefined) {
    return <Spinner size={"large"} />;
  }

  const contactMethods = [
    { label: "Text", value: 0 },
    { label: "Email", value: 1 }
  ];

  const positions = [
    { label: "Center", value: 0 },
    { label: "Wing", value: 1 },
    { label: "Defense", value: 3 }
  ];

  const onSubmit = (values: SubbingPrefs) => {
    form.validateFields()
        .then((validValues: SubbingPrefs) => {
          postReq.send( {...validValues, personId: user.personId },
              () => message.success("Subbing preferences updated."));
        });
  };

  const getInitPref = (key: string) => _.get(prefs.data, key);

  const arrayValidator = (rule: any, value: number[] | undefined) => {
    if (value === undefined || value.length === 0) {
      return Promise.reject(new Error("Must select at least one option."));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Divider>Subbing Preferences</Divider>
      <Form
          onFinish={onSubmit}
          layout={"vertical"}
          form={form}
          style={{ maxWidth: "500px" }}
      >
        <Form.Item
            label={"Email"}
            name={"email"}
            rules={[{ required: true, message: "Email is required." }]}
            initialValue={getInitPref("email")}>
          <Input />
        </Form.Item>
        <Form.Item
            label={"Phone Number"}
            name={"phoneNumber"}
            rules={[{ required: true, message: "Phone number is required." }]}
            initialValue={getInitPref("phoneNumber")}>
          <Input />
        </Form.Item>
        <Form.Item
            label={"Preferred Contact Methods"}
            name={"contactMethods"}
            rules={[{ validator: arrayValidator }]}
            initialValue={getInitPref("contactMethods")}>
          <Checkbox.Group options={contactMethods} />
        </Form.Item>
        <Form.Item
            label={"Positions Played"}
            name={"positions"}
            rules={[{ validator: arrayValidator }]}
            initialValue={getInitPref("positions")}>
          <Checkbox.Group options={positions} />
        </Form.Item>
        <Form.Item>
          <Button
            type={"primary"}
            loading={postReq.isLoading}
            htmlType={"submit"}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider>Subbing Availability</Divider>
      <AttendanceTable
        games={availabilities.data}
        isLoading={availabilityPostReq.isLoading || availabilities.isLoading}
        showAttendanceButtons={true}
        onClick={(row, modifyType) =>
          updateAvailability(availabilityPostReq, user, row, modifyType)
        }
        attendanceColLabel={"Available"}
      />
    </>
  );
}
