import React from "react";
import {Link} from "react-router-dom";
import {HOME} from "../routes";
import logo from "../../images/logo.png";

export function Logo() {
  return (
    <Link to={HOME}>
      <img
        src={logo}
        style={{ float: "left", padding: "5px" }}
        alt={"NCWHL"}
      />
    </Link>
  )
}
