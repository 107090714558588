import React, { useState, ChangeEvent } from "react";
import { usePost } from "../../common/hooks";
import { Input, Button, message } from "antd";
import Icon from '@ant-design/icons';
import { LOGIN } from "../../common/routes";
import {useNavigate, useParams} from "react-router";

export function PasswordReset() {
  const { personId, code } = useParams();
  let navigate = useNavigate();
  const postReq = usePost({ uri: "/api/resetPasswordRequest", asForm: true });
  const [password, setPassword] = useState<string>("");

  const onSuccess = () => {
    message.success("Successfully reset password. Please log in.");
    navigate(LOGIN)
  };

  return (
    <>
      <div style={{ width: 300, marginBottom: 10 }}>
        <Input
          prefix={<Icon type={"lock"} className={"IconPrefix"} />}
          placeholder={"Enter new password"}
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
      </div>
      <Button
        type={"primary"}
        onClick={() => postReq.send({ personId, code, password }, onSuccess)}
        disabled={!password}
        loading={postReq.isLoading}
      >
        Reset Password
      </Button>
    </>
  );
}
