import React, { useState } from "react";
import { Button, Modal } from "antd";
import { PostRequest } from "../interfaces";

/** Kind of like Popconfirm but looks nicer and allows for more customization. */
export function ConfirmationPopUp(props: {
  title: string;
  text: string;
  confirmLabel: string;
  onConfirm: PostRequest;
  params?: any;
}) {
  const { title, text, confirmLabel, onConfirm, params } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hideModal = () => setModalVisible(false);

  const footer = [
    <Button type={"primary"} onClick={hideModal}>
      Cancel
    </Button>,
    <Button
      danger
      onClick={() => onConfirm.send(params, hideModal)}
      loading={onConfirm.isLoading}
    >
      {confirmLabel}
    </Button>
  ];

  return (
    <>
      <Button
        danger
        size={"small"}
        onClick={() => setModalVisible(true)}
      >
        {confirmLabel}
      </Button>

      <Modal
        visible={modalVisible}
        footer={footer}
        onCancel={hideModal}
        title={title}
      >
        {text}
      </Modal>
    </>
  );
}
