import React, { useState } from "react";
import { useGet, usePost } from "../../common/hooks";
import { Button, Form, InputNumber, Modal } from "antd";
import { getPenaltiesTableData } from "../../common/utils";
import { TableWithDeleteButtons } from "./TableWithDeleteButtons";
import { GameEvent, Penalty, PostRequest } from "../../common/interfaces";
import { PlayerSelect } from "./PlayerSelect";
import { AddButton } from "./AddButton";
import { SelectWithCustomOption } from "../../common/components/SelectWithCustomOption";
import { PeriodSelect } from "../../common/components/PeriodSelect";

export function PenaltiesEditor(props: { gameId: number; teamId: number }) {
  const { gameId, teamId } = props;

  const penalties = useGet<Penalty[]>(
    `/api/penalties?gameId=${gameId}&teamId=${teamId}`
  );
  const deletePostReq = usePost({
    uri: "/api/deletePenalty",
    deps: [penalties]
  });
  const createPostReq = usePost({
    uri: "/api/createPenalty",
    deps: [penalties]
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hideModal = () => setModalVisible(false);

  const { data, columns } = getPenaltiesTableData(penalties.data);

  return (
    <>
      <TableWithDeleteButtons
        data={data}
        columns={columns}
        onDelete={(penalty: Penalty) =>
          deletePostReq.send({ gameId, penaltyId: penalty.id })
        }
        emptyText={"No Penalties"}
        loading={penalties.isLoading}
        buttonLoading={deletePostReq.isLoading}
      />
      <AddButton text={"Add penalty"} onClick={() => setModalVisible(true)} />
      <Modal open={modalVisible} footer={null} onCancel={hideModal}>
        <PenaltyForm
          gameId={gameId}
          teamId={teamId}
          postReq={createPostReq}
          hideModal={hideModal}
        />
      </Modal>
    </>
  );
}

interface PenaltyFormProps {
  gameId: number;
  teamId: number;
  postReq: PostRequest;
  hideModal: () => void;
}

interface PenaltyFormValues extends GameEvent {
  penaltyPersonId: number;
  penaltyType: string;
}

function PenaltyForm({ gameId, teamId, postReq, hideModal }: PenaltyFormProps) {
  const [form] = Form.useForm<PenaltyFormValues>();

  const onSuccess = () => {
    form.resetFields();
    hideModal();
  };

  const onSubmit = (values: PenaltyFormValues) => {
    form.validateFields()
      .then((validValues: PenaltyFormValues) => {
        postReq.send( {...validValues, teamId, gameId }, onSuccess);
      });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };

  return (
    <Form onFinish={onSubmit} layout={"horizontal"} form={form}>
      <Form.Item
          label={"Period"}
          name={"period"}
          rules={[{ required: true }]}
          {...formItemLayout}>
          <PeriodSelect />
      </Form.Item>
      <Form.Item
          label={"Minute"}
          name={"minute"}
          rules={[{ required: true }]}
          {...formItemLayout}>
        <InputNumber min={0} max={15}></InputNumber>
      </Form.Item>
      <Form.Item
          label={"Second"}
          name={"second"}
          rules={[{ required: true }]}
          {...formItemLayout}>
        <InputNumber min={0} max={60}></InputNumber>
      </Form.Item>
      <Form.Item
          label={"Player"}
          name={"penaltyPersonId"}
          {...formItemLayout}>
        <PlayerSelect gameId={gameId} teamId={teamId}></PlayerSelect>
      </Form.Item>
      <Form.Item
          label={"Penalty"}
          name={"penaltyType"}
          rules={[{ required: true }]}
          {...formItemLayout}>
        <SelectWithCustomOption initOptions={PENALTIES}></SelectWithCustomOption>
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={postReq.isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const PENALTIES = [
  "High Sticking",
  "Holding",
  "Tripping",
  "Slashing",
  "Unsportsmanlike Conduct (2 minutes)",
  "Shooting Puck After Whistle",
  "Interference",
  "Goalie Interference",
  "Too Many Players",
  "Boarding",
  "Charging",
  "Broken Stick",
  "Checking From Behind",
  "Cross Checking",
  "Delay of Game",
  "Diving",
  "Elbowing",
  "Hooking",
  "Roughing",
  "Checking",
  "Technical",
  "Body Checking",
  "Unsportsmanlike Conduct (10 minutes)",
  "Unsportsmanlike Conduct (game)",
  "Playing with more than one stick"
];
