import React, {useState} from "react";
import {useGet, usePost} from "../../common/hooks";
import {formatISODate} from "../../common/utils";
import {Discount, PlayerType, PostRequest, SeasonInfo} from "../../common/interfaces";
import {CompactTable} from "../../common/components/CompactTable";
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Switch} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {DeleteOutlined} from "@ant-design/icons";
import Spinner from "../../common/components/Spinner";


interface DiscountTableData extends Discount {
  amount: string;
}

function toTableData(discount: Discount) {
  return {...discount, amount: discount.amtOff > 0 ? `$${discount.amtOff}` : `${discount.pctOff}%`} as DiscountTableData;
}

function renderDelete(seasonId: number, req: PostRequest, val: string, row: DiscountTableData) {
  return (
      <Button
          icon={<DeleteOutlined />}
          style={{color: "red", border: "none", padding: "0 0 0 10px"}}
          onClick={() => req.send({seasonId, discountId: row.id})} />
  );
};

export function SeasonDiscountEditor(props: { seasonId: number, seasonOrder: number }) {
  const { seasonId, seasonOrder } = props;

  const seasonInfo = useGet<SeasonInfo>(`/api/season?seasonId=${seasonOrder}`);

  const deletePostReq = usePost({
    uri: "/api/discounts/delete",
    deps: [seasonInfo]
  });
  const createPostReq = usePost({
    uri: "/api/discounts/create",
    deps: [seasonInfo]
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hideModal = () => setModalVisible(false);

  if (seasonInfo.data === undefined ) {
    return <Spinner size={"large"}/>;
  }

  const discounts = seasonInfo.data.allDiscounts
      .map(d => toTableData(d))
      .toSorted((d1, d2) => d1.description.localeCompare(d2.description));

  const noWrapSpan = (val: string) => (
      <span style={{ whiteSpace: "nowrap" }}>{val}</span>
  );

  const columns = [
    { dataIndex: "id" },
    { dataIndex: "description", title: "Description" },
    { dataIndex: "code", title: "Code" },
    { dataIndex: "isAutoApplied",
      title: "Applied Automatically?",
      render: (val: boolean) => (val ? "Yes" : ""),},
    {
      dataIndex: "eligibleRegistrationTypes",
      title: "Eligible Registration Types",
      render: (val: string[]) => noWrapSpan(val.length === 3 ? 'All' : val.join(", "))
    },
    { dataIndex: "amount", title: "Discount" },
    {
      dataIndex: "expirationDate",
      title: "Expiration",
      render: (val: string) => noWrapSpan(val == null ? 'N/A' : formatISODate(val, "MMM dd"))
    },
    {
      dataIndex: "buttons", title: "",
      render: (val: string, row: DiscountTableData) => renderDelete(seasonId, deletePostReq, val, row)
    }
  ];

  return (
      <>
        <AddButton
            text={"Create Discount"}
            disabled={seasonInfo.isLoading}
            onClick={() => setModalVisible(true)}/>
        <CompactTable
            data={discounts}
            loading={seasonInfo.isLoading}
            columns={columns.filter(
                c => !c.dataIndex || c.dataIndex !== 'id'
            )}
            rowKey={"code"}
        />
        <Modal visible={modalVisible} footer={null} onCancel={hideModal}>
          <DiscountForm
              seasonId={seasonId}
              postReq={createPostReq}
              hideModal={hideModal}
          />
        </Modal>
      </>
  );
}

function AddButton(props: { text: string; onClick: () => void, disabled: boolean }) {
  const { text, onClick, disabled } = props;
  return (
      <Button style={{ marginTop: "10px" }} type={"primary"} disabled={disabled} onClick={onClick}>
        <PlusCircleOutlined />
        {` ${text}`}
      </Button>
  );
}

interface DiscountFormProps {
  discountId?: number;
  seasonId: number;
  postReq: PostRequest;
  hideModal: () => void;
}

interface DiscountFormValues {
  description: string;
  code: string;
  registrationTypes: number[];
  pctOff: number;
  amtOff: number;
  expirationDate: string;
}

function generateRandomCode() {
  return  Math.random()
      .toString(36)
      .slice(2)
      .toUpperCase();
}

function DiscountForm(props: DiscountFormProps) {
  const { discountId, seasonId, postReq, hideModal } = props;
  const [form] = Form.useForm<DiscountFormValues>();

  const onSuccess = () => {
    form.resetFields();
    hideModal();
  };

  const onSubmit = (values: DiscountFormValues) => {
    form.validateFields()
        .then((validValues: DiscountFormValues) => {
          postReq.send( {...validValues, seasonId, discountId }, onSuccess);
        });
  };

  const registrationTypes = [
    { label: "Skater", value: PlayerType.SKATER },
    { label: "Goalie", value: PlayerType.GOALIE },
    { label: "Skater & Goalie", value: PlayerType.SKATER_AND_GOALIE }
  ];
  const discountTypes = [
    { label: 'Percentage', value: 'pctOff' }, // remember to pass the key prop
    { label: 'Fixed amount', value: 'amtOff' },
  ];

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 }
  };
  const arrayValidator = (rule: any, value: number[] | undefined) => {
    if (value === undefined || value.length === 0) {
      return Promise.reject(new Error("Must select at least one option."));
    }
    return Promise.resolve();
  };
  const codeRequired = Form.useWatch('codeRequired', form);
  const discountType = Form.useWatch('discountType', form);

  return (
      <Form onFinish={onSubmit} layout={"horizontal"} form={form}>
        <Form.Item
            label={"Description"}
            name={"description"}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Input />
        </Form.Item>
        <Form.Item
            label={"Code required?"}
            name={"codeRequired"}
            initialValue={"checked"}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Switch size="small" defaultChecked={true} />
        </Form.Item>
        <Form.Item
            label={"Code"}
            name={"code"}
            initialValue={generateRandomCode()}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Input disabled={!codeRequired} />
        </Form.Item>
        <Form.Item
            label={"Registration Types"}
            name={"registrationTypes"}
            rules={[{ validator: arrayValidator }]}>
          <Checkbox.Group options={registrationTypes} />
        </Form.Item>
        <Form.Item
            label={"Discount Type"}
            name={"discountType"}
            rules={[{ required: true }]}
            initialValue={"pctOff"}
            {...formItemLayout}>
          <Radio.Group options={discountTypes} />
        </Form.Item>
        <Form.Item
            label={"% Off"}
            name={"pctOff"}
            rules={[{ required: discountType === "pctOff" }]}
            {...formItemLayout}>
          <InputNumber min={0} max={100} disabled={discountType !== "pctOff"}></InputNumber>
        </Form.Item>
        <Form.Item
            label={"Amount Off"}
            name={"amtOff"}
            rules={[{ required: discountType === "amtOff"}]}
            {...formItemLayout}>
          <InputNumber min={0} disabled={discountType !== "amtOff"}></InputNumber>
        </Form.Item>
        <Form.Item
            name={"expirationDate"}
            label={"Expiration Date"}
            {...formItemLayout}>
          <DatePicker format={"YYYY-MM-DD"}></DatePicker>
        </Form.Item>
        <Form.Item>
          <Button
              type={"primary"}
              htmlType={"submit"}
              loading={postReq.isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
  );
}
