import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { Team } from "../../common/interfaces";
import { Button, Form, Input, message } from "antd";
import Spinner from "../../common/components/Spinner";

interface TeamNameValues {
  teamId: number;
  teamName: string;
}

interface TeamNameFormProps {
  teamId: number;
}

export function TeamNameForm({teamId} : TeamNameFormProps) {
  const teamInfo = useGet<Team>(`/api/team?teamId=${teamId}`);
  const [form] = Form.useForm();
  const updateTeamName = usePost({
    uri: "/api/updateTeamName",
    deps: [teamInfo]
  });

  const onSubmit = (values: TeamNameValues) => {
    form.validateFields()
      .then((validValues: TeamNameValues) => {
        updateTeamName.send({ ...validValues, teamId }, () => {
          message.success("Team name updated.");
        });
      });
  };

  if (!teamInfo.data) {
    return <Spinner size={"large"} />;
  }

  return (
    <>
      <Form
        onFinish={onSubmit}
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          alignItems: "center"
        }}
        form={form}
      >
        <Form.Item
            name={"teamName"}
            initialValue={teamInfo.data.name}
            style={{ marginBottom: 0, marginRight: 10 }}>
          <Input />
        </Form.Item>
        <Button
          type={"primary"}
          disabled={!teamInfo}
          htmlType={"submit"}
          loading={updateTeamName.isLoading}
        >
          Update Team Name
        </Button>
      </Form>
    </>
  );
}
