import React, { useState, ChangeEvent } from "react";
import { LoginForm } from "../components/LoginForm";
import { Modal, Input, Button, message } from "antd";
import Icon from '@ant-design/icons';
import { usePost } from "../hooks";
import { AppEngineService, SERVICE_NAME } from "../constants";
import { FileUpload } from "../components/FileUpload";
import {useNavigate} from "react-router";
import {REGISTER} from "../routes";

export function LoginPage() {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  let navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const postReq = usePost({ uri: "/api/requestPasswordReset" });

  const hideModal = () => setModalVisible(false);

  const sendPasswordReset = () => {
    postReq.send({ email }, () => {
      hideModal();
      message.success("Password reset email sent.");
    });
  };

  return (
    <>
      <LoginForm />
      {window.location.href.startsWith("http://localhost:") && (
        <FileUpload
          apiPath={"/api/importData"}
          uploadLabel={"Upload DB File"}
        />
      )}
      {SERVICE_NAME === AppEngineService.PLAYERS && (
        <>
          <Button
            style={{ padding: 0 }}
            type={"link"}
            onClick={() => setModalVisible(true)}
          >
            Forgot password?
          </Button>
          <div>
            Don't have an account?
            <Button
              style={{ paddingLeft: 5 }}
              type={"link"}
              onClick={() => navigate(REGISTER)}
            >
              Register here
            </Button>
          </div>
          <Modal
            visible={modalVisible}
            onOk={sendPasswordReset}
            onCancel={() => {
              setEmail("");
              hideModal();
            }}
            okText={"Send password reset email"}
            okButtonProps={{ disabled: !email.includes("@") }}
            confirmLoading={postReq.isLoading}
          >
            <Input
              style={{ width: "75%" }}
              prefix={<Icon type={"mail"} className={"IconPrefix"} />}
              placeholder={"Enter your email address"}
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              onPressEnter={sendPasswordReset}
            />
          </Modal>
        </>
      )}
    </>
  );
}
