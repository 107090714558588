import React, { useState } from "react";
import { Select } from "antd";
import { useGet } from "../../common/hooks";
import _ from "lodash";
import { Attendance, RosterPlayer } from "../../common/interfaces";
import { getPlayerDisplay } from "../../common/utils";

export function PlayerSelect(props: {
  gameId: number;
  teamId: number;
  excludePlayers?: (number | undefined)[];
  includeEmpty?: boolean;
  disabled?: boolean;
  value?: number | string;
  onChange?: (val: number | string) => void;
}) {
  const {
    gameId,
    teamId,
    excludePlayers,
    includeEmpty,
    disabled,
    value,
    onChange
  } = props;
  const players = useGet<RosterPlayer[]>(
    `/api/attendance?gameId=${gameId}&teamId=${teamId}`
  );
  const [open, setOpen] = useState<boolean>(false);

  let eligiblePlayers: RosterPlayer[] = [];
  if (players.data) {
    eligiblePlayers = _.sortBy(
      players.data.filter(p => p.attendance === Attendance.IN),
      "jerseyNumber"
    );
  }

  if (excludePlayers) {
    eligiblePlayers = eligiblePlayers.filter(
      p => !excludePlayers.includes(p.id)
    );
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      loading={players.isLoading}
      showSearch={true}
      optionFilterProp={"children"}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      onSelect={() => setOpen(false)}
      open={open}
    >
      {includeEmpty && (
        <Select.Option key={"default"} value={""}>
          {"Choose a player"}
        </Select.Option>
      )}
      {eligiblePlayers.map((p: RosterPlayer) => (
        <Select.Option key={p.id} value={p.id}>
          {getPlayerDisplay(p)}
        </Select.Option>
      ))}
    </Select>
  );
}
