import React from "react";
import { useGet } from "../../common/hooks";
import { Penalty } from "../../common/interfaces";
import { getPenaltiesTableData } from "../../common/utils";
import { CompactTable } from "../../common/components/CompactTable";

export function PenaltiesTable(props: { gameId: number; teamId: number }) {
  const { gameId, teamId } = props;
  const penalties = useGet<Penalty[]>(
    `/api/penalties?gameId=${gameId}&teamId=${teamId}`
  );
  const { data, columns } = getPenaltiesTableData(penalties.data);
  return (
    <CompactTable
      data={data}
      columns={columns}
      loading={penalties.isLoading}
      emptyText={"No Penalties"}
    />
  );
}
