import React, {useState} from "react";
import {useGet, usePost} from "../hooks";
import {
  Alert,
  Button,
  Col,
  message,
  Row,
  Select,
  Statistic
} from "antd";
import {ScoresheetUrl} from "../routes";
import {
  Attendance,
  Game,
  RosterCoach,
  RosterPlayer,
  SubstitutePlayer,
  Team
} from "../interfaces";
import _ from "lodash";
import {Link, useParams} from "react-router-dom";
import {getPlayerDisplay, sortPlayersByDivisionAndJersey} from "../utils";
import {useEnsuredUser} from "../contexts";
import {GoalieSelector} from "../components/GoalieSelector";
import {GameMetaHeader} from "../components/GameMetaHeader";
import {AppEngineService, SERVICE_NAME} from "../constants";
import Spinner from "../components/Spinner";
import {
  RosterAttendanceTable,
  TagProps
} from "../components/RosterAttendanceTable";
import {TeamEmailButton} from "../components/TeamEmailForm";
import {ArrowLeftOutlined, MailOutlined, SaveOutlined, SearchOutlined} from "@ant-design/icons";
import {SubListUrl} from "../../players/routes";

interface RosterProps {
  linkBackToScoresheet?: boolean;
}

export function Roster({linkBackToScoresheet}: RosterProps) {
  const {gameId, teamId} = useParams();
  // Hooks
  const user = useEnsuredUser();
  const game = useGet<Game>(`/api/games?gameId=${gameId!}`);
  const team = useGet<Team>(`/api/team?teamId=${teamId!}`);
  const players = useGet<RosterPlayer[]>(
      `/api/attendance?gameId=${gameId!}&teamId=${teamId!}`
  );
  const attendancePostReq = usePost({
    uri: "/api/modifyPlayerAttendance",
    deps: [players]
  });

  const invitePostReq = usePost({uri: "/api/sendInvites", deps: [players]});

  // Roster can be edited if:
  // * we are in the scorekeepers app, which can only be accessed by the
  //   scorekeeper login or an admin login
  // * we are in the players app and the logged-in user is a captain of
  //   this team
  const isCaptainOfThisTeam =
      user.isCaptain && user.teamId === _.toNumber(teamId);
  const editable =
      SERVICE_NAME === AppEngineService.SCOREKEEPERS || isCaptainOfThisTeam;
  const teamUsesJvite = team.data && team.data.usingJVite;

  const coaches = useGet<RosterCoach[]>(
      `/api/getCoachesAttendance?gameId=${gameId!}&teamId=${teamId!}`
  );
  const coachAttendancePostReq = usePost({
    uri: "/api/modifyCoachAttendance",
    deps: [coaches]
  });

  const toTags = (row: RosterPlayer) => {
    const tags: TagProps[] = [];
    if (row.isSub) {
      tags.push({tag: "Sub", color: "cyan"});
    }
    if (row.isCaptain) {
      tags.push({tag: "C", color: "blue"});
    }
    if (row.inviteSent && isCaptainOfThisTeam && teamUsesJvite) {
      tags.push({tag: <MailOutlined/>, color: "purple"});
    }
    return tags;
  };

  const onModifyAttendance = (row: RosterPlayer, modifyType: string) => {
    attendancePostReq.send({
      gameId: gameId!,
      teamId: teamId!,
      personId: row.id,
      modifyType
    });
  };

  const onModifyCoachAttendance = (row: RosterCoach, modifyType: string) => {
    if (!coachAttendancePostReq) return;
    coachAttendancePostReq.send({
      gameId: gameId!,
      teamId: teamId!,
      coachId: row.id,
      modifyType
    });
  };

  const invitesSent =
      players.data && players.data.find(p => p.inviteSent) !== undefined;
  const numUnrespondedPlayers = (players.data || []).filter(
      p => p.attendance === Attendance.UNRESPONDED
  ).length;
  const numInPlayers = (players.data || []).filter(
      p => p.attendance === Attendance.IN
  ).length;
  const needsInvites =
      !invitesSent && teamUsesJvite && numUnrespondedPlayers > 0;
  let invitesJustSent = false;

  return (
      <>
        {isCaptainOfThisTeam && needsInvites && !invitesJustSent && (
            <Row hidden={!needsInvites}>
              <Col>
                <Alert
                    message={
                      <>
                        Invites scheduled to be sent out a week before the game.{" "}
                        <Button
                            type="link"
                            disabled={invitePostReq.isLoading}
                            onClick={() =>
                                invitePostReq.send({gameId: gameId!, teamId: teamId!}, () => {
                                      invitesJustSent = true;
                                      message.success("Invites sent!");

                                    }
                                )
                            }
                        >
                          Send now
                        </Button>
                      </>
                    }
                    type="info"
                />
              </Col>
            </Row>
        )}
        <div style={{marginBottom: 10}}>
          {(() => {
            if (!game.data) {
              return <Spinner size={"default"}/>;
            } else {
              return (
                  <GameMetaHeader game={game.data} teamId={_.toNumber(teamId)}/>
              );
            }
          })()}
        </div>
        <Row gutter={32}>
          <Col>
            <Statistic title="Attending" value={numInPlayers}/>
          </Col>
          <Col>
            <Statistic title="Unresponded" value={numUnrespondedPlayers}/>
          </Col>
          {isCaptainOfThisTeam && teamUsesJvite && (
              <Col>
                <Statistic
                    title="Invites Sent"
                    value={invitesSent ? "Yes" : "No"}
                />
              </Col>
          )}
        </Row>
        <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: 10
            }}
        >
          {linkBackToScoresheet && (
              <Link to={ScoresheetUrl.createUrl(gameId!)}>
                <Button type={"primary"} icon={<ArrowLeftOutlined/>} style={{marginRight: 10}}>
                  Back
                </Button>
              </Link>
          )}
          {editable && (
              <SubDropdown
                  gameId={gameId!}
                  teamId={teamId!}
                  onSuccess={() => players.reload()}
              />
          )}
        </div>
        {/* Only captains can send reminder emails.*/}
        <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: 10
            }}
        >
          {isCaptainOfThisTeam && teamUsesJvite && (
              <TeamEmailButton
                  gameResponse={game}
                  playersResponse={players}
                  defaultSubject={"Message about your game"}
              />
          )}
          {isCaptainOfThisTeam && (
              <Link to={SubListUrl.createUrl(gameId!, teamId!)}>
                <Button type={"primary"} icon={<SearchOutlined/>}>
                  View Subs List
                </Button>
              </Link>
          )}
        </div>
        <RosterAttendanceTable
            data={_.sortBy(players.data || [], "jerseyNumber")}
            disableButtons={!editable}
            requestLoading={attendancePostReq.isLoading}
            dataLoading={!players.data || !team.data}
            modifyAttendance={onModifyAttendance}
            columnLabel={"Player"}
            getEntityDisplay={getPlayerDisplay}
            toTags={toTags}
            showDeleteButton={(row: RosterPlayer) => row.isSub}
        />
        {isCaptainOfThisTeam && (coaches.data || []).length > 0 && (
            <div style={{marginTop: 20}}>
              <RosterAttendanceTable
                  data={_.sortBy(coaches.data || [], "firstName")}
                  disableButtons={!editable}
                  requestLoading={attendancePostReq.isLoading}
                  modifyAttendance={onModifyCoachAttendance}
                  columnLabel={"Coach"}
                  getEntityDisplay={(row: RosterCoach) =>
                      `${row.firstName} ${row.lastName}`
                  }
              />
            </div>
        )}
        <div style={{marginTop: 20}}>
          <strong style={{marginRight: 5}}>Goalie:</strong>
          {(() => {
            if (editable) {
              return <GoalieSelector gameId={gameId!} teamId={teamId!}/>;
            } else if (game.data) {
              const goalieName =
                  game.data.team1Id === _.toNumber(teamId!)
                      ? game.data.team1GoalieName
                      : game.data.team2GoalieName;
              return goalieName || "Unknown";
            } else {
              return <Spinner size={"small"}/>;
            }
          })()}
        </div>
      </>
  );
}

function SubDropdown(props: {
  gameId: string;
  teamId: string;
  onSuccess: () => void;
}) {
  const {gameId, teamId, onSuccess} = props;
  const subs = useGet<SubstitutePlayer[]>(
      `/api/subs?gameId=${gameId}&teamId=${teamId}&augmented=false`
  );
  const [sub, setSub] = useState<number | undefined>(undefined);
  const postReq = usePost({uri: "/api/modifyPlayerAttendance", deps: [subs]});

  const onSave = () => {
    postReq.send(
        {gameId, teamId, personId: sub, modifyType: "add"},
        onSuccess
    );
    setSub(undefined);
  };

  const selectOpts = sortPlayersByDivisionAndJersey(subs.data || []);

  return (
      <>
        <Select
            showSearch={true}
            onChange={(val: number) => setSub(val)}
            value={sub}
            style={{width: 320}}
            placeholder={"Add a substitute"}
            loading={subs.isLoading}
            optionFilterProp={"children"}
        >
          {selectOpts.map((sub: SubstitutePlayer) => (
              <Select.Option key={sub.id} value={sub.id}>
                {getPlayerDisplay(sub)}
              </Select.Option>
          ))}
        </Select>{" "}
        <Button
            style={{marginLeft: 5}}
            icon={<SaveOutlined/>}
            type={"primary"}
            onClick={onSave}
            disabled={sub === undefined}
            loading={postReq.isLoading || subs.isLoading}
        />
      </>
  );
}
