import React, { useState } from "react";
import { useGet } from "../../common/hooks";
import { CompactTable } from "../../common/components/CompactTable";
import _ from "lodash";
import { SeasonInfo, SeasonRegistration } from "../../common/interfaces";
import { Select } from "antd";
import { ensure, formatISODate } from "../../common/utils";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { PersonDashboardUrl} from "../routes";
import {DownloadOutlined} from "@ant-design/icons";

export function RegistrationList() {
  const seasons = useGet<SeasonInfo[]>("/api/season/all");
  const latestSeason: number | undefined = _.last(
    (seasons.data || [])
      .filter(e => e.isOpenForRegistration)
      .sort(e => e.order)
      .map(e => e.id)
  );
  const [selectedSeason, setSelectedSeason] = useState<number | undefined>(
    undefined
  );
  const [filters, setFilters] = useState<
    { [key: string]: string[] } | undefined
  >(undefined);
  const regs = useGet<SeasonRegistration[]>(
    latestSeason !== undefined
      ? `/api/registration/all?seasonEntityId=${selectedSeason || latestSeason}`
      : undefined
  );

  const noWrapSpan = (val: string) => (
    <span style={{ whiteSpace: "nowrap" }}>{val}</span>
  );

  const onFilter = (row: SeasonRegistration, propertyName: string): boolean => {
    const vals = _.get(filters, propertyName) || [];
    return vals.includes(_.toString(_.get(row, propertyName)));
  };

  const columns = [
    {
      key: "index",
      title: "#",
      render: (text: string, row: SeasonRegistration, idx: number) => idx + 1
    },
    {
      key: "name",
      title: "Name",
      render: (text: string, row: SeasonRegistration) => {
        const name = `${row.firstName} ${row.lastName}`;
        if (row.personId) {
          return (
            <Link to={PersonDashboardUrl.createUrl(row.personId)}>
              {noWrapSpan(name)}
            </Link>
          );
        } else {
          return name;
        }
      }
    },
    { dataIndex: "email", title: "Email" },
    { dataIndex: "phoneNumber", title: "Phone", render: noWrapSpan },
    { dataIndex: "address", title: "Address" },
    { dataIndex: "city", title: "City" },
    { dataIndex: "zip", title: "ZIP" },
    {
      dataIndex: "dateOfBirth",
      title: "DOB",
      render: noWrapSpan
    },

    { dataIndex: "usaHockeyNumber", title: "USA Hockey", render: noWrapSpan },
    {
      dataIndex: "currentDivision",
      title: "Current Division",
      filters: divisionFilters,
      onFilter: (value: string, row: SeasonRegistration) =>
        onFilter(row, "currentDivision")
    },
    {
      dataIndex: "requestedDivision",
      title: "Requested Division",
      filters: divisionFilters,
      onFilter: (value: string, row: SeasonRegistration) =>
        onFilter(row, "requestedDivision")
    },
    {
      dataIndex: "playerType",
      title: "Position",
      render: noWrapSpan,
      filters: [
        { text: "Skater", value: "Skater" },
        { text: "Goalie", value: "Goalie" },
        { text: "Skater & Goalie", value: "Skater & Goalie" }
      ],
      onFilter: (value: string, row: SeasonRegistration) =>
        onFilter(row, "playerType")
    },
    {
      dataIndex: "needsEval",
      title: "Needs Eval",
      render: (val: boolean) => (val ? "Yes" : ""),
      filters: [{ text: "Yes", value: "true" }, { text: "No", value: "false" }],
      onFilter: (value: string, row: SeasonRegistration) =>
        onFilter(row, "needsEval")
    },
    {
      dataIndex: "discounts",
      title: "Discounts",
    },
    { dataIndex: "total", title: "Paid", render: (val: number) => `$${val}` },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => noWrapSpan(formatISODate(val, "MMM dd"))
    }
  ];

  const csvHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    ...columns
      .filter(c => !!c.dataIndex)
      .map((c: any) => ({
        label: ensure(c.title) as string,
        key: ensure(c.dataIndex) as string
      }))
  ];

  const data = _.sortBy(regs.data || [], "date");

  const hideColumns = [
    "address",
    "city",
    "zip",
    "dateOfBirth",
    "usaHockeyNumber",
    "email",
    "phoneNumber",
  ];

  return (
    <div style={{ maxWidth: "80vw" }}>
      <Select
        value={selectedSeason || latestSeason}
        onChange={(val: number) => setSelectedSeason(val)}
        loading={seasons.isLoading}
        style={{ width: 250, marginBottom: 10 }}
      >
        {(seasons.data || [])
          .sort((s1, s2) => s2.order - s1.order)
          .map(s => (
            <Select.Option key={s.id} value={s.id}>
              {s.name}
            </Select.Option>
          ))}
      </Select>
      <CSVLink
        data={data}
        headers={csvHeaders}
        filename={"registrations.csv"}
        className={"ant-btn ant-btn-primary"}
        style={{ marginLeft: 5 }}
      >
        <DownloadOutlined /> Download as CSV
      </CSVLink>
      <CompactTable
        loading={regs.isLoading}
        data={data}
        columns={columns.filter(
          c => !c.dataIndex || !hideColumns.includes(c.dataIndex)
        )}
        rowKey={"email"}
        onChange={f => setFilters(f)}
      />
    </div>
  );
}

const divisionFilters = [
  { text: "Green", value: "Green" },
  { text: "Red", value: "Red" },
  { text: "Maroon", value: "Maroon" },
  { text: "Blue", value: "Blue" },
  { text: "Unknown", value: "Unknown" }
];
