import _ from "lodash";
import { useGet, usePost } from "../../common/hooks";
import { Player } from "../../common/interfaces";
import { getDivisionName, getPlayerDisplay } from "../../common/utils";
import { Button, Col, Descriptions, Row, Select } from "antd";
import React, { useState } from "react";

interface PlayerOrGoalie extends Player {
  isGoalie: boolean;
}

function PlayerInfo(props: {
  player: Player;
  title: string;
  loading: boolean;
  playersAndGoalies: PlayerOrGoalie[];
  onChange: (playerId: number) => void;
}) {
  const { loading, onChange, player, playersAndGoalies, title } = props;

  function getPlayerOrGoalieDisplay(playerOrGoalie: PlayerOrGoalie) {
    return playerOrGoalie.isGoalie
      ? `(Goalie): ${playerOrGoalie.firstName} ${playerOrGoalie.lastName}`
      : getPlayerDisplay(playerOrGoalie);
  }

  let isActiveLabel = "";
  let teamLabel = "";
  if (player.id > 0) {
    isActiveLabel = player.active ? "Yes" : "No";
    teamLabel = player.teamName || "N/A";
  }
  return (
    <>
      <Select
        showSearch={true}
        style={{ width: 250 }}
        placeholder={"Player to merge (delete)"}
        loading={loading}
        optionFilterProp={"children"}
        onChange={onChange}
      >
        {playersAndGoalies.map((player: PlayerOrGoalie) => (
          <Select.Option key={player.id} value={player.id}>
            {getPlayerOrGoalieDisplay(player)}
          </Select.Option>
        ))}
      </Select>
      <Descriptions title={title} column={1}>
        <Descriptions.Item label="Name">
          {player.firstName} {player.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{player.email}</Descriptions.Item>
        <Descriptions.Item label="Active">{isActiveLabel}</Descriptions.Item>
        <Descriptions.Item label="Division">
          {getDivisionName(player.division)}
        </Descriptions.Item>
        <Descriptions.Item label="Jersey #">
          {player.jerseyNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Team">{teamLabel}</Descriptions.Item>
      </Descriptions>
    </>
  );
}

function sortPlayersThenGoalies(players: PlayerOrGoalie[]) {
  return _.orderBy(
    players,
    ["isGoalie", "division", "jerseyNumber"],
    ["asc", "desc", "asc"]
  );
}

function getAllOptions(players?: Player[], goalies?: Player[]) {
  const mappedPlayers = (players || []).map(p => p as PlayerOrGoalie);
  mappedPlayers.forEach(p => (p.isGoalie = false));
  const mappedGoalies = (goalies || []).map(p => p as PlayerOrGoalie);
  mappedGoalies.forEach(p => (p.isGoalie = true));

  return sortPlayersThenGoalies(mappedPlayers.concat(mappedGoalies));
}

export function MergePlayers() {
  const emptyPlayer = {
    id: -1,
    firstName: "",
    lastName: "",
    email: ""
  } as PlayerOrGoalie;
  const [player1, setPlayer1] = useState<PlayerOrGoalie>(emptyPlayer);
  const [player2, setPlayer2] = useState<PlayerOrGoalie>(emptyPlayer);

  const allPlayers = useGet<Player[]>("/api/getPlayers");
  const allGoalies = useGet<Player[]>("/api/goalies");
  const mergePlayers = usePost({ uri: "/api/mergePeople", deps: [allPlayers] });
  const mergeGoalies = usePost({
    uri: "/api/mergeGoalies",
    deps: [allGoalies]
  });

  const allPlayersOrGoalies = getAllOptions(allPlayers.data, allGoalies.data);

  function getPlayer(playerId: number) {
    const selectedPlayer = allPlayersOrGoalies
      .filter(p => p.id === playerId)
      .pop();
    return selectedPlayer ? selectedPlayer : emptyPlayer;
  }

  function clearPlayers() {
    setPlayer1(emptyPlayer);
    setPlayer2(emptyPlayer);
  }

  return (
    <div>
      <Row>
        <Col>
          Use this tool to merge players (or goalies). There is no way to undo a
          merge, so make sure you double-check what you're doing!
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <PlayerInfo
            title={"Player to merge (delete)"}
            player={player1}
            loading={allPlayers.isLoading || allGoalies.isLoading}
            playersAndGoalies={allPlayersOrGoalies}
            onChange={(playerId: number) => setPlayer1(getPlayer(playerId))}
          />
        </Col>
        <Col span={8}>
          <PlayerInfo
            title={"Player to keep"}
            player={player2}
            loading={allPlayers.isLoading || allGoalies.isLoading}
            playersAndGoalies={allPlayersOrGoalies}
            onChange={(playerId: number) => setPlayer2(getPlayer(playerId))}
          />
        </Col>
      </Row>
      <Row>
        <Button
          type={"primary"}
          disabled={
            player1.id <= 0 ||
            player2.id <= 0 ||
            player1.id === player2.id ||
            player1.isGoalie !== player2.isGoalie
          }
          loading={mergeGoalies.isLoading || mergePlayers.isLoading}
          onClick={() =>
            player1.isGoalie
              ? mergeGoalies.send(
                  { goalie1: player1.id, goalie2: player2.id },
                  clearPlayers
                )
              : mergePlayers.send(
                  { person1: player1.id, person2: player2.id },
                  clearPlayers
                )
          }
        >
          Merge
        </Button>
      </Row>
    </div>
  );
}
