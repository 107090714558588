import _ from "lodash";
import { Game, PostRequest, Rink } from "../../common/interfaces";
import { usePost } from "../../common/hooks";
import { Button, DatePicker, Divider, Form, message, Select } from "antd";
import { ScoresheetUrl} from "../../common/routes";
import React from "react";
import { formatISODate } from "../../common/utils";
import moment from "moment";

export function EditGameForm(props: { game: Game | undefined }) {
  const saveGame = usePost({ uri: "/api/editGame" });
  const { game } = props;

  if (!game) {
    return <Divider>Edit Game</Divider>;
  }

  return (
    <>
      <Divider>Edit Game</Divider>
      <div>
        <p>
          Edit the details of this game. To modify the scoresheet{" "}
          <a
            href={`https://scorekeepers.ncwhl.hockey${ScoresheetUrl.createUrl(_.toString(game.id))}`}
          >
            view this game
          </a>{" "}
          in the scorkeepers app.
        </p>
      </div>
      <div />
      <GameForm game={game} postReq={saveGame} />
    </>
  );
}

interface GameFormProps {
  game: Game;
  postReq: PostRequest;
}

interface GameFormValues {
  date: string;
  rink: number;
}

export function GameForm(props: GameFormProps) {
  const { game, postReq } = props;
  const [form] = Form.useForm();

  const gameId = game.id;


  const onSubmit = (values: GameFormValues) => {
    form.validateFields()
        .then((validValues: GameFormValues) => {
          postReq.send( {...validValues, gameId },
              () => message.success("Information Updated!"));
        });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };

  const dateFormat = "YYYY-MM-DD h:mma";
  const gameDay = formatISODate(game.date, "yyyy-MM-dd h:mma");

  return (
    <Form form={form} onFinish={onSubmit} layout={"horizontal"}>
      <Form.Item
          name={"date"}
          label={"Game Time"}
          rules={[{ required: true, message: "Game time is required." }]}
          initialValue={moment(gameDay, dateFormat)}
          {...formItemLayout}>
        <DatePicker showTime={{ format: "h:mma" }} format={dateFormat} />
      </Form.Item>
      <Form.Item
          label={"Teams"}
          {...formItemLayout}>
        <span>{`${game.team1Name}/${game.team2Name}`}</span>
      </Form.Item>
      <Form.Item
          name={"rink"}
          label={"Rink"}
          rules={[{ required: true, message: "Rink is required." }]}
          initialValue={game.rinkId}
          {...formItemLayout}>
          <Select>
            <Select.Option key={Rink.SAN_MATEO} value={Rink.SAN_MATEO}>
              San Mateo
            </Select.Option>
            <Select.Option key={Rink.REDWOOD_CITY} value={Rink.REDWOOD_CITY}>
              Redwood City
            </Select.Option>
          </Select>
      </Form.Item>
      <Form.Item>
        <Button
            type={"primary"}
            htmlType={"submit"}
            loading={postReq.isLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
