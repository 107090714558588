import React from "react";

export function TextWithIconPrefix(props: {
  text: string | React.ReactNode;
  icon: React.ReactNode;
}) {
  const { text, icon } = props;
  return (
    <div style={{ fontSize: "1.2em", padding: "5px" }}>
      <span>
        <span style={{ paddingRight: "10px", color: "rgba(0,0,0,.25)" }}>
          {icon}
        </span>
        {text}
      </span>
    </div>
  );
}
