import { useGet, usePost } from "../../common/hooks";
import { Button, Select } from "antd";
import React from "react";

interface PenaltyType {
  id: number;
  label: string;
}

interface PenaltyWithGame {
  id: number;
  gameId: number;
  customPenaltyLabel: string;
  penaltyTypeId: number;
}

export function CustomPenalties() {
  const customPenalties = useGet<PenaltyWithGame[]>("/api/getCustomPenalties");
  const penaltyTypes = useGet<PenaltyType[]>("/api/getPenaltyTypes");
  const savePenalty = usePost({
    uri: "/api/updatePenaltyType",
    deps: [customPenalties]
  });

  let selectedPenaltyUpdates = new Map<number, number>();
  const types = penaltyTypes.data;

  function onSubmit(penalty: PenaltyWithGame) {
    return () =>
      savePenalty.send({
        id: penalty.id,
        gameId: penalty.gameId,
        penaltyTypeId: selectedPenaltyUpdates.get(penalty.id)
      });
  }

  return (
    <>
      {(customPenalties.data || []).map((penalty: PenaltyWithGame) => (
        <div key={penalty.id}>
          {penalty.customPenaltyLabel}
          <Select
            showSearch={true}
            style={{ width: 250, margin: 5 }}
            placeholder={"Penalty type"}
            loading={penaltyTypes.isLoading}
            defaultValue={penalty.penaltyTypeId}
            onChange={(penaltyTypeId: number) =>
              selectedPenaltyUpdates.set(penalty.id, penaltyTypeId)
            }
            optionFilterProp={"children"}
          >
            {(types || []).map((penaltyType: PenaltyType) => (
              <Select.Option key={penaltyType.id} value={penaltyType.id}>
                {penaltyType.label}
              </Select.Option>
            ))}
          </Select>
          <Button
            type={"primary"}
            style={{ margin: 5 }}
            disabled={savePenalty.isLoading}
            onClick={onSubmit(penalty)}
          >
            Save
          </Button>
        </div>
      ))}
    </>
  );
}
