import _ from "lodash";
import React, { useState } from "react";
import { useGet, usePost } from "../../common/hooks";
import { Game, Scrimmage } from "../../common/interfaces";
import { Button, Col, Row, Select, Statistic, Table, Tabs } from "antd";
import { formatISODate } from "../../common/utils";
import { ScoresheetUrl} from "../../common/routes";
import { EditGameForm } from "../components/EditGameForm";
import { FileUpload } from "../../common/components/FileUpload";
import { ScrimmageRosterUrl } from "../routes";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

interface UploadedGame {
  id: number;
  rink: string;
  time: string;
  homeTeam: string;
  awayTeam: string;
  error: string;
}

const renderScoresheet = (gameId: number) => {
  return (
    <>
      <a
        href={`https://scorekeepers.ncwhl.hockey${ScoresheetUrl.createUrl(_.toString(gameId))}`}
      >
        Scoresheet
      </a>
    </>
  );
};

const renderScrimmage = (scrimmageId: number) => {
  return (
    <Link to={ScrimmageRosterUrl.createUrl(scrimmageId)}>Roster</Link>
  );
};

export function GamesDashboard() {
  const allGames = useGet<Game[]>("/api/games");
  const scrimmages = useGet<Scrimmage[]>("/api/scrimmages");
  const uploadedGames = useGet<UploadedGame[]>("/api/uploadedGames");
  const saveUploadedGames = usePost({
    uri: "/api/submitUploadedGames",
    deps: [uploadedGames, allGames]
  });
  const [gameToEdit, setGameToEdit] = useState<Game | undefined>(undefined);

  const sortedGames = _.sortBy(allGames.data || [], "date");
  const firstGameDate =
    sortedGames.length > 0
      ? formatISODate(sortedGames[0].date, "MMM dd yyyy")
      : "";
  const lastGameDate =
    sortedGames.length > 0
      ? formatISODate(sortedGames[sortedGames.length - 1].date, "MMM dd yyyy")
      : "";
  const sortedScrimmages = _.sortBy(scrimmages.data || [], "date");
  const sortedUploads = _.sortBy(uploadedGames.data || [], "time");
  const uploadedGamesColumns = [
    { dataIndex: "time", title: "Date" },
    { dataIndex: "rink", title: "Rink" },
    { dataIndex: "homeTeam", title: "Home Team" },
    { dataIndex: "awayTeam", title: "Away Team" },
    { dataIndex: "error", title: "Error" }
  ];

  const gamesColumns = [
    { dataIndex: "rinkName", title: "Rink" },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => formatISODate(val)
    },
    { dataIndex: "team1Name", title: "Home" },
    { dataIndex: "team2Name", title: "Away" },
    { dataIndex: "id", title: "Scoresheet", render: renderScoresheet }
  ];
  const scrimmageColumns = [
    { dataIndex: "rinkName", title: "Rink" },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => formatISODate(val)
    },
    { dataIndex: "id", title: "Roster", render: renderScrimmage }
  ];

  const canSubmit =
    sortedUploads.length > 0 && !sortedUploads.find(g => g.error.length > 0);

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Current Games" key="1">
          <Row>
            <Col span={8}>
              <Statistic
                title={"# Games"}
                value={allGames.data ? allGames.data.length : ""}
              />
            </Col>
            <Col span={8}>
              <Statistic title={"First Game"} value={firstGameDate} />
            </Col>
            <Col span={8}>
              <Statistic title={"Last Game"} value={lastGameDate} />
            </Col>
          </Row>
          <Table
            dataSource={sortedGames}
            columns={gamesColumns}
            rowKey={"id"}
            loading={allGames.isLoading}
            scroll={{ x: true }}
          />
        </TabPane>
        <TabPane tab="Upload Games" key="2">
          <div style={{ marginBottom: 10 }}>
            File should contain rows: date (d-MMM-yy), time (kk:mm), home team,
            and away team. Remove any non-game rows, such as practices or holiday breaks.
          </div>
          <pre style={{
            background: "lightgray",
            width: "fit-content",
            padding: "10px" }}>
            <b>Date,Time,Home,Away</b>
            <br />
            7-Jan-24,16:45,R1,R2
          </pre>
          <FileUpload
            apiPath={"/api/uploadGamesCsv"}
            uploadLabel={"Click to Upload"}
            successMessage={"Uploaded games successfully!"}
            onSuccess={uploadedGames.reload}
            failureMessage={"Failed to upload games."}
          />
          <Table
            dataSource={sortedUploads}
            columns={uploadedGamesColumns}
            rowKey={"id"}
            loading={uploadedGames.isLoading}
            scroll={{ x: true }}
          />
          <Button
            type={"primary"}
            style={{ width: 150 }}
            disabled={!canSubmit || saveUploadedGames.isLoading}
            onClick={() => saveUploadedGames.send({})}
          >
            Save
          </Button>
        </TabPane>
        <TabPane tab="Edit Games" key="3">
          <Select
            showSearch={true}
            style={{ width: 350 }}
            placeholder={"Game"}
            loading={allGames.isLoading}
            optionFilterProp={"children"}
            onChange={(gameId: number) =>
              setGameToEdit(
                (allGames.data || []).filter(g => g.id === gameId).pop()
              )
            }
          >
            {(allGames.data || []).map((game: Game) => (
              <Select.Option key={game.id} value={game.id}>
                {`${formatISODate(game.date, "MMM dd yyyy h:mm")} ${
                  game.rinkName
                } ${game.team1Name}/${game.team2Name}`}
              </Select.Option>
            ))}
          </Select>
          <EditGameForm game={gameToEdit} />
        </TabPane>
        <TabPane tab="Scrimmages" key="4">
          <Table
            dataSource={sortedScrimmages}
            columns={scrimmageColumns}
            rowKey={"id"}
            loading={scrimmages.isLoading}
            scroll={{ x: true }}
          />
        </TabPane>
      </Tabs>
    </>
  );
}
