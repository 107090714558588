import _ from "lodash";
import React from "react";
import {useGet, usePost} from "../../common/hooks";
import {Division, Player, PostRequest, Team} from "../../common/interfaces";
import {Button, Card, Divider, Dropdown, List, Menu, Tabs} from "antd";
import {allKnownDivisions, getDivisionName} from "../../common/utils";
import {CompactTable} from "../../common/components/CompactTable";

const {TabPane} = Tabs;

function getMenu(
    personId: number,
    teams: Team[],
    assignPlayerToTeam: PostRequest
) {
  return (
      <Menu>
        {teams.map(team => (
            <Menu.Item
                key={team.id}
                onClick={() =>
                    assignPlayerToTeam.send({
                      personId: personId,
                      teamId: team.id,
                      isCaptain: false
                    })
                }
            >
              {team.name}
            </Menu.Item>
        ))}
      </Menu>
  );
}

function getDivisionTab(
    teams: Team[],
    players: Player[],
    assignPlayerToTeam: PostRequest,
    removePlayerFromTeam: PostRequest,
    division: Division
) {
  const playersInDivision = players.filter(p => p.division === division);
  const teamsInDivision = teams.filter(t => t.division === division);
  const playersWithoutTeam = playersInDivision.filter(p => !p.onTeam);

  return (
      <>
        {playersWithoutTeam.length > 0 && (
            <List
                dataSource={playersWithoutTeam}
                grid={{gutter: 16, column: 6}}
                renderItem={player => (
                    <List.Item>
                      <Dropdown
                          key={player.id}
                          overlay={getMenu(
                              player.id,
                              teamsInDivision,
                              assignPlayerToTeam
                          )}
                          placement="bottomLeft"
                      >
                        <p>
                          {player.firstName} {player.lastName}
                        </p>
                      </Dropdown>
                    </List.Item>
                )}
            />
        )}
        <List
            dataSource={teamsInDivision}
            grid={{gutter: 16, column: 6}}
            renderItem={team => (
                <List.Item>
                  <Card title={team.name}
                        extra={`${playersInDivision
                            .filter(p => p.teamId === team.id).length} players`}>
                    {playersInDivision
                        .filter(p => p.teamId === team.id)
                        .map((player: Player) => (
                            <Dropdown
                                key={player.id}
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                        onClick={() =>
                                            removePlayerFromTeam.send({
                                              personId: player.id,
                                              teamId: team.id
                                            })
                                        }
                                    >
                                      Remove from {team.name}
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() =>
                                            assignPlayerToTeam.send({
                                              personId: player.id,
                                              teamId: team.id,
                                              isCaptain: !player.isCaptain
                                            })
                                        }
                                    >
                                      {player.isCaptain
                                          ? "Remove captain"
                                          : "Assign captain"}
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomLeft"
                            >
                              <p>
                                {player.firstName} {player.lastName}{player.isCaptain ? ' (C)' : ''}
                              </p>
                            </Dropdown>
                        ))}
                  </Card>
                </List.Item>
            )}
        />
      </>
  );
}

export function TeamsDashboard() {
  const allTeams = useGet<Team[]>("/api/teams");
  const createTeam = usePost({uri: "/api/createTeam", deps: [allTeams]});
  const allPlayers = useGet<Player[]>("/api/getPlayers");
  const assignPlayerToTeam = usePost({
    uri: "/api/addPlayerToTeam",
    deps: [allTeams, allPlayers]
  });
  const removePlayerFromTeam = usePost({
    uri: "/api/removePlayerFromTeam",
    deps: [allTeams, allPlayers]
  });

  const teamsColumns = [
    {dataIndex: "name", title: "Name"},
    {dataIndex: "numPlayers", title: "# players"},
    {
      dataIndex: "usingJVite",
      title: "Using JVite?",
      render: (value: boolean) => (value ? "Yes" : "No")
    }
  ];

  const sortedTeams = _.sortBy(allTeams.data || [], ["division", "teamId"]);
  const sortedPlayers = _.sortBy(
      (allPlayers.data || [] as Player[]).filter(p => p.active),
      ["division", "lastName", "firstName"]);

  return (
      <>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Current Teams" key="1">
            {allKnownDivisions.map((division: Division) => (
                <div key={division}>
                  <Divider>{getDivisionName(division)} Teams</Divider>
                  <Button
                      type={"primary"}
                      onClick={() => createTeam.send({divisionId: division})}
                  >
                    Add {getDivisionName(division)} Team
                  </Button>
                  <CompactTable
                      data={sortedTeams.filter(t => t.division === division)}
                      columns={teamsColumns}
                      rowKey={"id"}
                      loading={allTeams.isLoading}
                  />
                </div>
            ))}
          </TabPane>
          <TabPane tab="Green" key="2">
            {getDivisionTab(
                sortedTeams,
                sortedPlayers,
                assignPlayerToTeam,
                removePlayerFromTeam,
                Division.GREEN
            )}
          </TabPane>
          <TabPane tab="Red" key="3">
            {getDivisionTab(
                sortedTeams,
                sortedPlayers,
                assignPlayerToTeam,
                removePlayerFromTeam,
                Division.RED
            )}
          </TabPane>
          <TabPane tab="Maroon" key="4">
            {getDivisionTab(
                sortedTeams,
                sortedPlayers,
                assignPlayerToTeam,
                removePlayerFromTeam,
                Division.MAROON
            )}
          </TabPane>
          <TabPane tab="Blue" key="5">
            {getDivisionTab(
                sortedTeams,
                sortedPlayers,
                assignPlayerToTeam,
                removePlayerFromTeam,
                Division.BLUE
            )}
          </TabPane>
        </Tabs>
      </>
  );
}
