import React from "react";
import Icon from '@ant-design/icons';

export function NotFound() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div>
        <Icon type="frown" style={{ fontSize: "50px", color: "black" }} />
      </div>
      <div style={{ marginTop: 20 }}>This page does not exist.</div>
    </div>
  );
}
