import React from "react";
import { PlayersApp } from "../../players/PlayersApp";
import { ScorekeepersApp } from "../../scorekeepers/ScorekeepersApp";
import { useUserContext } from "../contexts";
import { UserAuth } from "../interfaces";
import { AppEngineService, SERVICE_NAME } from "../constants";
import { AdminApp } from "../../admin/AdminApp";

export function LoggedInContainer() {
  const { user } = useUserContext();

  const authTypeOfApp = (() => {
    switch (SERVICE_NAME) {
      case AppEngineService.PLAYERS:
        return UserAuth.PLAYER;
      case AppEngineService.SCOREKEEPERS:
        return UserAuth.SCOREKEEPER;
      case AppEngineService.ADMIN:
        return UserAuth.ADMIN;
    }
  })();

  const app = (() => {
    switch (SERVICE_NAME) {
      case AppEngineService.PLAYERS:
        return <PlayersApp />;
      case AppEngineService.SCOREKEEPERS:
        return <ScorekeepersApp />;
      case AppEngineService.ADMIN:
        return <AdminApp />;
    }
  })();

  return (
    <div style={{ background: "white", padding: "10px" }}>
      {(() => {
        if (user!.authTypes.includes(authTypeOfApp)) {
          return app;
        } else {
          return (<div>You are not authorized to view this page.</div>);
        }
      })()}
    </div>
  );
}
