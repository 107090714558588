import React from "react";
import paypalDonate from "../../images/paypal-donate.png";

export function Donate() {
  return (
    <div style={{ maxWidth: 400 }}>
      <p>
        Would you like to make a donation to the Assistance Fund? This money
        helps skaters who need financial assistance play in our league. As
        little as $1 can be donated; any amount is appreciated. Thank you!
      </p>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="W8YQ4CPZQ7A8J" />
        <input
          style={{ width: 125 }}
          type="image"
          src={paypalDonate}
          name="submit"
          alt="Donate with PayPal"
        />
      </form>
    </div>
  );
}
