import React, { useState } from "react";
import { ScrimmageAttendance } from "../interfaces";
import { ensure, formatISODate } from "../utils";
import { AttendanceButtons } from "./AttendanceButtons";
import { CompactTable } from "./CompactTable";

interface ButtonState {
  scrimmageId?: number;
  modifyType?: string;
}

export function ScrimmageAttendanceTable(props: {
  scrimmages?: ScrimmageAttendance[];
  isLoading: boolean;
  onClick: (row: ScrimmageAttendance, modifyType: string) => void;
  attendanceColLabel?: string;
}) {
  const [buttonState, setButtonState] = useState<ButtonState>({});

  const onClickAttendanceButton = (
    row: ScrimmageAttendance,
    modifyType: string
  ) => {
    setButtonState({ scrimmageId: row.id, modifyType });
    props.onClick(row, modifyType);
  };

  const renderAttendance = (row: ScrimmageAttendance) => {
    const inButtonLoading =
      props.isLoading &&
      buttonState.scrimmageId === row.id &&
      buttonState.modifyType === "add";
    const outButtonLoading =
      props.isLoading &&
      buttonState.scrimmageId === row.id &&
      buttonState.modifyType === "remove";
    return (
      <AttendanceButtons
        attendance={ensure(row.attendance)}
        onClick={(modifyType: string) =>
          onClickAttendanceButton(row, modifyType)
        }
        inButtonLoading={inButtonLoading}
        outButtonLoading={outButtonLoading}
      />
    );
  };
  const scrimmageColumns = [
    { dataIndex: "rinkName", title: "Rink" },
    {
      dataIndex: "date",
      title: "Date",
      render: (val: string) => formatISODate(val)
    },
    {
      dataIndex: "playerAttendance",
      title: props.attendanceColLabel || "Attendance",
      render: (text: string, row: ScrimmageAttendance) => renderAttendance(row)
    }
  ];
  return (
    <CompactTable
      data={props.scrimmages || []}
      columns={scrimmageColumns}
      loading={!props.scrimmages}
    />
  );
}
