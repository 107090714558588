import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { useEnsuredUser } from "../../common/contexts";
import { ScrimmageAttendance } from "../../common/interfaces";
import { dtNow, toModifyType } from "../../common/utils";
import _ from "lodash";
import { Divider } from "antd";
import { ScrimmageAttendanceTable } from "../../common/components/ScrimmageAttendanceTable";

export function Scrimmages() {
  const user = useEnsuredUser();
  const scrimmages = useGet<ScrimmageAttendance[]>(
    `/api/scrimmages?personId=${user.personId}`
  );
  const availabilities = useGet<ScrimmageAttendance[]>(
    `/api/getScrimmageAvailabilities?personId=${user.personId}`
  );
  const postReq = usePost({
    uri: "/api/modifyScrimmageAttendance",
    deps: [scrimmages]
  });
  const availabilityPostReq = usePost({
    uri: "/api/modifyScrimmageAvailability",
    deps: [availabilities]
  });

  const partitionScrimmages = (
    scrimmages: ScrimmageAttendance[]
  ): { beforeNow: ScrimmageAttendance[]; afterNow: ScrimmageAttendance[] } => {
    const now = dtNow();
    const [beforeNow, afterNow] = _.partition(scrimmages, g => g.date < now);
    return {
      beforeNow: _.sortBy(beforeNow, g => g.date).reverse(),
      afterNow: _.sortBy(afterNow, g => g.date)
    };
  };

  const partition = partitionScrimmages(scrimmages.data || []);

  return (
    <>
      <Divider>Upcoming Scrimmages</Divider>
      <div>
        <p>
          The following are scrimmages you've been selected to play in. If
          you're unable to make it, please mark yourself as 'out' as soon as
          possible.
        </p>
      </div>
      <ScrimmageAttendanceTable
        scrimmages={scrimmages.data ? partition.afterNow : undefined}
        isLoading={postReq.isLoading || scrimmages.isLoading}
        onClick={(row, modifyType) =>
          postReq.send({
            scrimmageId: row.id,
            personId: user.personId,
            modifyType: toModifyType(modifyType, row.attendance)
          })
        }
      />
      <Divider>Availability</Divider>
      <div>
        <p>
          Below are all the upcoming scrimmages available at this time. If
          you're interested in playing, mark your availability below. Note that
          marking your availability does not mean you're playing the scrimmage.
          You will be notified of any scrimmages you're selected to play in.
        </p>
      </div>
      <ScrimmageAttendanceTable
        scrimmages={availabilities.data}
        isLoading={availabilityPostReq.isLoading || availabilities.isLoading}
        onClick={(row, modifyType) =>
          availabilityPostReq.send({
            scrimmageId: row.id,
            personId: user.personId,
            modifyType: toModifyType(modifyType, row.attendance)
          })
        }
        attendanceColLabel={"Available"}
      />
    </>
  );
}
