import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { useEnsuredUser } from "../../common/contexts";
import { Game } from "../../common/interfaces";
import { dtNow, toModifyType } from "../../common/utils";
import _ from "lodash";
import { Divider } from "antd";
import { AttendanceTable } from "../../common/components/AttendanceTable";

export function GamesList() {
  const user = useEnsuredUser();
  const games = useGet<Game[]>(`/api/games?personId=${user.personId}`);
  const gamesToCoach = useGet<Game[]>(
    `/api/coachingGames?personId=${user.personId}`
  );
  const postReq = usePost({
    uri: "/api/modifyPlayerAttendance",
    deps: [games]
  });
  const coachingPostReq = usePost({
    uri: "/api/modifyCoachAttendance",
    deps: [gamesToCoach]
  });

  const partitionGames = (
    games: Game[]
  ): { beforeNow: Game[]; afterNow: Game[] } => {
    const now = dtNow();
    const [beforeNow, afterNow] = _.partition(games, g => g.date < now);
    return {
      beforeNow: _.sortBy(beforeNow, g => g.date).reverse(),
      afterNow: _.sortBy(afterNow, g => g.date)
    };
  };

  const sortedGamesToCoach = _.sortBy(gamesToCoach.data || [], g => g.date);
  const partition = partitionGames(games.data || []);
  const iscoach = sortedGamesToCoach.length > 0;

  return (
    <>
      <Divider>Upcoming Games</Divider>
      <AttendanceTable
        games={games.data ? partition.afterNow : undefined}
        isLoading={postReq.isLoading || games.isLoading}
        showAttendanceButtons={user.teamUsesJvite}
        onClick={(row, modifyType) =>
          postReq.send({
            gameId: row.id,
            teamId: user.teamId,
            personId: user.personId,
            modifyType: toModifyType(modifyType, row.playerAttendance)
          })
        }
        showSubIndicator={true}
      />
      {iscoach && (
        <>
          <Divider>Upcoming Games to Coach</Divider>
          <AttendanceTable
            games={sortedGamesToCoach}
            isLoading={coachingPostReq.isLoading || gamesToCoach.isLoading}
            showAttendanceButtons={true}
            onClick={(row, modifyType) =>
              coachingPostReq.send({
                gameId: row.id,
                teamId: row.playerAttendanceTeamId,
                coachId: user.personId,
                modifyType: toModifyType(modifyType, row.playerAttendance)
              })
            }
          />
        </>
      )}
      <Divider>Past Games</Divider>
      <AttendanceTable
        games={games.data ? partition.beforeNow : undefined}
        isLoading={postReq.isLoading || games.isLoading}
        showAttendanceButtons={false}
        onClick={() => undefined}
        showSubIndicator={true}
        isPastGames={true}
      />
    </>
  );
}
