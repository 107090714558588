import React from "react";
import { useGet, usePost } from "../hooks";
import { Person, Game } from "../interfaces";
import _ from "lodash";
import { SelectWithCustomOption } from "./SelectWithCustomOption";

export function GoalieSelector(props: { gameId: string; teamId: string }) {
  const { gameId, teamId } = props;

  const game = useGet<Game>(`/api/games?gameId=${gameId}`);
  const goalies = useGet<Person[]>("/api/goalies");
  const postReq = usePost({ uri: "/api/submitRoster", deps: [game] });

  let eligibleGoalies: Person[] = [];
  let curGoalieName: string | undefined = undefined;
  let otherGoalieId: number | undefined = undefined;
  if (game.data && goalies.data) {
    if (game.data.team1Id === _.toNumber(teamId)) {
      curGoalieName = game.data.team1GoalieName;
      otherGoalieId = game.data.team2Goalie;
    } else {
      curGoalieName = game.data.team2GoalieName;
      otherGoalieId = game.data.team1Goalie;
    }
    // Don't include the other team's goalie in the dropdown.
    eligibleGoalies = goalies.data.filter(
      (g: Person) => otherGoalieId !== g.id
    );
  }

  const getFullName = (g: Person) =>
    [g.firstName, g.lastName].filter(n => !!n).join(" ");
  const eligibleGoalieNames = _.uniq(eligibleGoalies.map(g => getFullName(g)));

  return (
    <>
      <SelectWithCustomOption
        initOptions={eligibleGoalieNames}
        value={curGoalieName}
        onChange={(goalie: string) => {
          postReq.send({
            goalie,
            gameId,
            teamId,
            originalGoalieName: curGoalieName
          });
        }}
        loading={game.isLoading || goalies.isLoading || postReq.isLoading}
        style={{ width: 250 }}
      />
    </>
  );
}
