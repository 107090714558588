import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { Person, User } from "../../common/interfaces";
import { Button, Col, Form, message, Row, Select } from "antd";
import {DeleteOutlined} from "@ant-design/icons";

interface CoachFormValues {
  coachId: number;
}

interface CoachInfoFormProps {
  user: User;
  enabled: boolean;
}

export function CoachInfoForm({user, enabled}: CoachInfoFormProps) {
  const [form] = Form.useForm();

  const isCaptain = user.teamId !== undefined && user.isCaptain;

  const possibleCoaches = useGet<Person[]>("/api/getCoaches");
  const currentCoaches = useGet<Person[]>(
    `/api/getCoach?teamId=${user.teamId}`
  );
  const removeCoachReq = usePost({
    uri: "/api/removeCoach",
    deps: [currentCoaches]
  });
  const addCoachReq = usePost({ uri: "/api/setCoach", deps: [currentCoaches] });

  const onSubmit = (values: CoachFormValues) => {
    form.validateFields()
      .then((validValues: CoachFormValues) => {
        addCoachReq.send({ ...validValues, teamId: user.teamId }, () => {
          form.resetFields();
          message.success("Coach added.");
        });
      });
  };

  if (!isCaptain) return <></>;

  const currentCoachIds = (currentCoaches.data || []).map(coach => coach.id);
  const eligibleCoaches = (possibleCoaches.data || []).filter(
    coach => !currentCoachIds.includes(coach.id)
  );

  return (
    <>
      <Form
        onFinish={onSubmit}
        form={form}
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          alignItems: "center"
        }}
      >
        <Form.Item
            name={"coachId"}
            style={{ marginBottom: 0, marginRight: 10 }}>
          <Select
            loading={eligibleCoaches.length === 0}
            placeholder={"Select a coach"}
            showSearch={true}
            disabled={!enabled}
            optionFilterProp={"children"}
            style={{ width: 350 }}
          >
            {eligibleCoaches.map((coach: Person) => (
              <Select.Option key={coach.id} value={coach.id}>
                {`${coach.firstName} ${coach.lastName} (${coach.email})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          type={"primary"}
          disabled={!enabled}
          htmlType={"submit"}
          loading={addCoachReq.isLoading}
        >
          Add Coach
        </Button>
      </Form>
      {(currentCoaches.data || []).map((coach: Person) => (
        <Row gutter={12}>
          <Col style={{ fontWeight: "bold" }}>Coach:</Col>
          <Col>{`${coach.firstName} ${coach.lastName} (${coach.email})`}</Col>
          <Col>
            <Button danger
                    type={"primary"}
                    icon={<DeleteOutlined />}
                    shape={"circle"}
                    onClick={() => removeCoachReq.send({ teamId: user.teamId, coachId: coach.id })}
                    loading={removeCoachReq.isLoading}
            />
          </Col>
        </Row>
      ))}
    </>
  );
}
