import React from "react";
import { Select } from "antd";

export function PeriodSelect(props: {
  value?: string;
  onChange?: (val: string) => void;
}) {
  return (
    <Select
      showSearch={true}
      value={props.value}
      onChange={props.onChange}
      autoFocus={true}
    >
      <Select.Option key={"1"} value={"1"}>
        1
      </Select.Option>
      <Select.Option key={"2"} value={"2"}>
        2
      </Select.Option>
      <Select.Option key={"3"} value={"3"}>
        3
      </Select.Option>
      <Select.Option key={"4"} value={"4"}>
        OT
      </Select.Option>
    </Select>
  );
}
