import React, { useState } from "react";
import { EntityWithAttendance } from "../interfaces";
import { toModifyType } from "../utils";
import { AttendanceButtons } from "./AttendanceButtons";
import { CompactTable } from "./CompactTable";
import { useEnsuredUser } from "../contexts";
import { Tag } from "antd";
import Icon from '@ant-design/icons';

interface AttendanceButtonState {
  id?: number;
  modifyType?: string;
}

export interface TagProps {
  tag: string | React.ReactNode;
  color: string;
}

export function RosterAttendanceTable<T extends EntityWithAttendance>(props: {
  disableButtons: boolean;
  data: T[];
  requestLoading: boolean;
  dataLoading?: boolean;
  columnLabel: string;
  modifyAttendance: (row: T, modifyType: string) => void;
  toTags?: (row: T) => TagProps[];
  showDeleteButton?: (row: T) => boolean;
  deleteButtonModifyType?: string;
  getEntityDisplay: (row: T) => string;
}) {
  const {
    modifyAttendance,
    data,
    requestLoading,
    columnLabel,
    disableButtons,
    getEntityDisplay,
    dataLoading,
    toTags,
    showDeleteButton,
    deleteButtonModifyType
  } = props;
  const [buttonState, setButtonState] = useState<AttendanceButtonState>({});
  const user = useEnsuredUser();

  const onModifyAttendance = (row: T, modifyType: string) => {
    setButtonState({ id: row.id, modifyType });
    modifyAttendance(row, toModifyType(modifyType, row.attendance));
  };

  const renderEntity = (text: string, row: T) => {
    const tags = toTags ? toTags(row) : [];
    const renderDelete = showDeleteButton && showDeleteButton(row);

    if (tags.length || renderDelete) {
      return (
        <span>
          {getEntityDisplay(row)}
          {tags.map((t: TagProps, i: number) => (
            <Tag color={t.color} key={`${i}`} style={{ marginLeft: 5 }}>
              {t.tag}
            </Tag>
          ))}
          {renderDelete && !disableButtons && (
            <Icon
              type={outButtonIsLoading(row) ? "loading" : "delete"}
              style={{ color: "red", cursor: "pointer" }}
              onClick={() =>
                onModifyAttendance(row, deleteButtonModifyType || "remove")
              }
            />
          )}
        </span>
      );
    } else {
      return getEntityDisplay(row);
    }
  };

  const renderAttendance = (text: string, row: T) => {
    return (
      <AttendanceButtons
        attendance={row.attendance}
        onClick={(modifyType: string) => onModifyAttendance(row, modifyType)}
        disabled={user.personId === row.id ? false : disableButtons}
        inButtonLoading={inButtonIsLoading(row)}
        outButtonLoading={outButtonIsLoading(row)}
      />
    );
  };
  const inButtonIsLoading = (row: EntityWithAttendance) => {
    return (
      requestLoading &&
      buttonState.id === row.id &&
      buttonState.modifyType === "add"
    );
  };
  const outButtonIsLoading = (row: EntityWithAttendance) => {
    return (
      requestLoading &&
      buttonState.id === row.id &&
      buttonState.modifyType === "remove"
    );
  };

  const columns = [
    { key: "id", title: columnLabel, render: renderEntity },
    {
      dataIndex: "attendance",
      title: "Attendance",
      render: renderAttendance
    }
  ];
  return (
    <CompactTable
      data={data}
      columns={columns}
      loading={dataLoading}
      rowKey={"id"}
    />
  );
}
