import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import _ from "lodash";
import { PostRequest } from "./interfaces";

const CACHE = new Map<string, any>();

export interface GetRequest<T> {
  isLoading: boolean;
  data?: T;
  reload: () => void;
}

export function callApi<T>(path: string): Promise<T> {
  const headers = new Headers({ "Content-Type": "application/json" });

  return fetch(path, {
    method: "get",
    headers
  }).then((resp: Response) => {
    if (resp.status === 200) {
      return resp.json();
    }
    return Promise.resolve(new Error("Error for request " + path));
  });
}

export function useGet<T>(
  path: string | undefined,
  useCache: boolean = true
): GetRequest<T> {
  const [reloadDep, setReloadDep] = useState<number>(0);
  const [getReq, setGetReq] = useState<GetRequest<T>>({
    isLoading: path !== undefined,
    reload: () => undefined
  });
  const data = useRef<T | undefined>(undefined);

  useEffect(() => {
    if (path === undefined) {
      return;
    }

    const onReload = () => {
      CACHE.delete(path);
      setGetReq({
        isLoading: true,
        data: data.current,
        reload: () => undefined
      });
      setReloadDep(reloadDep + 1);
    };

    if (useCache && CACHE.has(path)) {
      data.current = CACHE.get(path);
      setGetReq({
        isLoading: false,
        data: data.current,
        reload: onReload
      });
      return;
    }

    const headers = new Headers({ "Content-Type": "application/json" });

    const fetchData = async () => {
      setGetReq({
        isLoading: true,
        data: data.current,
        reload: () => undefined
      });

      const resp = await fetch(path, {
        method: "get",
        headers
      });
      if (resp.status === 200) {
        const json = await resp.json();
        data.current = json;
        setGetReq({
          isLoading: false,
          data: json,
          reload: onReload
        });
        if (useCache) {
          CACHE.set(path, json);
        }
      } else {
        setGetReq({
          isLoading: false,
          data: undefined,
          reload: onReload
        });
      }
    };

    fetchData();
  }, [path, useCache, reloadDep]);
  return getReq;
}

export function usePost(props: {
  uri: string;

  // After a successful request, any deps listed here will be reloaded.
  deps?: GetRequest<any>[];

  // If true, sends data as form data instead of as query params.
  asForm?: boolean;
}): PostRequest {
  const { uri, deps, asForm } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const send = async (
    params?: any,
    onSuccess?: (data?: any) => void,
    onError?: () => void
  ) => {
    setIsLoading(true);

    let url = uri;
    let body;
    if (params) {
      if (asForm) {
        body = new URLSearchParams();
        for (const key in params) {
          const val = params[key];
          if (val !== undefined) {
            body.append(key, params[key]);
          }
        }
      } else {
        url += "?";
        for (const key in params) {
          const val = params[key];
          if (val !== undefined) {
            if (_.isArray(val)) {
              for (const elem of val) {
                url += `&${key}[]=${encodeURIComponent(elem)}`;
              }
            } else {
              url += `&${key}=${encodeURIComponent(val)}`;
            }
          }
        }
      }
    }

    const req = await fetch(url, {
      method: "post",
      headers: asForm
        ? { "Content-Type": "application/x-www-form-urlencoded" }
        : { "Content-Type": "application/json" },
      body,
      credentials: "include"
    });

    if (req.status === 200) {
      if (onSuccess) {
        try {
          const data = await req.json();
          onSuccess(data);
        } catch {
          onSuccess();
        }
      }
      for (const dep of deps || []) {
        dep.reload();
      }
    } else {
      let errorText = req.statusText;
      if (req.status !== 500) {
        try {
          errorText = await req.text();
        } catch {}
      }
      if (onError) {
        onError();
      }
      if (errorText) {
        message.error(errorText);
      }
    }

    setIsLoading(false);
  };

  return { send, isLoading };
}
