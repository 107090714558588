import React from "react";
import { usePost } from "../../common/hooks";
import { useEnsuredUser, useUserContext } from "../../common/contexts";
import { User } from "../../common/interfaces";
import { Col, Divider, Row, Switch } from "antd";
import { PersonInfoForm } from "../components/PersonInfoForm";
import { CoachInfoForm } from "../components/CoachInfoForm";
import { TeamNameForm } from "../components/TeamNameForm";

const jViteDoc =
  "https://docs.google.com/document/d/e/2PACX-1vR-WgHyydVzDVWdbkilvy80f2uB2IzrMTPjyIFjYgGejZPe1iomgGPcgdInmGcECK5fd32bAIM6Qwoi/pub";

export function Settings() {
  const user = useEnsuredUser();
  const { setUser } = useUserContext();
  const getUserInfo = usePost({ uri: "/api/user" });
  function resetUser() {
    getUserInfo.send({}, (data: User) => setUser(data));
  }
  const jVitePostReq = usePost({
    uri: "/api/setJViteOptIn"
  });

  const setJVite = (jViteChecked: boolean) => {
    jVitePostReq.send({ teamId: user.teamId, useJVite: jViteChecked }, () =>
      resetUser()
    );
  };

  return (
    <>
      {user.isCaptain && user.teamId && (
        <>
          <Divider>Team Settings</Divider>
          <Row gutter={12}>
            <Col>
              Enable{" "}
              <a href={jViteDoc} target={"_blank"}>
                JVite
              </a>{" "}
              for your team?
            </Col>
            <Col>
              <Switch
                title={user.teamUsesJvite ? "JVite enabled" : "JVite disabled"}
                checked={user.teamUsesJvite}
                onClick={setJVite}
              />
            </Col>
          </Row>
          <TeamNameForm teamId={user.teamId} />
          <CoachInfoForm user={user} enabled={user.teamUsesJvite} />
        </>
      )}
      <Divider>User Information</Divider>
      <PersonInfoForm user={user} />
    </>
  );
}
