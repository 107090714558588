import React from "react";
import { TextWithIconPrefix } from "./TextWithIconPrefix";
import { formatISODate } from "../utils";
import { Game } from "../interfaces";
import { CalendarOutlined, EnvironmentOutlined, TeamOutlined } from "@ant-design/icons";

export function GameMetaHeader(props: { game: Game; teamId?: number }) {
  const { game, teamId } = props;

  let teamName;
  let homeOrAway;
  if (teamId) {
    [teamName, homeOrAway] =
      teamId === game.team1Id
        ? [game.team1Name, "Home"]
        : [game.team2Name, "Away"];
  }

  return (
    <>
      {teamName && (
        <TextWithIconPrefix
          icon={<TeamOutlined />}
          text={`Team ${teamName} (${homeOrAway})`}
        />
      )}
      <TextWithIconPrefix
          icon={<CalendarOutlined />}
          text={formatISODate(props.game.date)}
      />
      <TextWithIconPrefix text={props.game.rinkName} icon={<EnvironmentOutlined />} />
    </>
  );
}
