import React from "react";
import { useGet } from "../../common/hooks";
import { Game } from "../../common/interfaces";
import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { GoalsTable } from "../components/GoalsTable";
import { PenaltiesTable } from "../components/PenaltiesTable";
import { GameMetaHeader } from "../../common/components/GameMetaHeader";
import Spinner from "../../common/components/Spinner";
import {useParams} from "react-router";

export function Scoresheet() {
  let { gameId } = useParams();
  const { data: game } = useGet<Game>(`/api/games?gameId=${gameId}`);
  const rowGutter = 24;
  const colSpan = 12;

  if (!game) {
    return <Spinner size={"large"} />;
  }

  return (
    <>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <GameMetaHeader game={game} />
        </Col>
        <Col span={colSpan}>
          <div style={{ display: "inline-block", paddingRight: 10 }}>
            <div>
              <strong>Scorekeeper:</strong>
            </div>
            <div>
              <strong>Referee 1:</strong>
            </div>
            <div>
              <strong>Referee 2:</strong>
            </div>
          </div>
          <div style={{ display: "inline-block" }}>
            <div>{game.scorekeeper}</div>
            <div>{game.referee1}</div>
            <div>{game.referee2}</div>
          </div>
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetTextRow"}>
        <Col span={colSpan}>
          <Title level={3}>{`Home (${game.team1Name})`}</Title>
        </Col>
        <Col span={colSpan}>
          <Title level={3}>{`Away (${game.team2Name})`}</Title>
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <GoalsTable gameId={game.id} teamId={game.team1Id} />
        </Col>
        <Col span={colSpan}>
          <GoalsTable gameId={game.id} teamId={game.team2Id} />
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <PenaltiesTable gameId={game.id} teamId={game.team1Id} />
        </Col>
        <Col span={colSpan}>
          <PenaltiesTable gameId={game.id} teamId={game.team2Id} />
        </Col>
      </Row>
    </>
  );
}
