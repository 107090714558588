import React, { useState } from "react";
import { ColumnType } from "antd/lib/table";
import { Button } from "antd";
import { CompactTable } from "../../common/components/CompactTable";
import {DeleteOutlined} from "@ant-design/icons";

interface Deleteable {
  id: number;
}

export function TableWithDeleteButtons<T extends Deleteable>(props: {
  data: object[];
  onDelete: (row: T) => void;
  columns: ColumnType<T>[];
  emptyText?: string;
  loading?: boolean;
  buttonLoading?: boolean;
}) {
  const { data, onDelete, columns, emptyText, loading, buttonLoading } = props;
  const [deletedId, setDeletedId] = useState<number | undefined>(undefined);

  const onClickDelete = (row: T) => {
    setDeletedId(row.id);
    onDelete(row);
  };

  const renderDelete = (text: string, row: T) => {
    return (
      <Button danger
        type={"primary"}
        icon={<DeleteOutlined />}
        shape={"circle"}
        onClick={() => onClickDelete(row)}
        loading={buttonLoading && row.id === deletedId}
      />
    );
  };

  const columnsWithDelete = [
    ...columns,
    { key: "delete", render: renderDelete }
  ];

  return (
    <CompactTable
      data={data}
      columns={columnsWithDelete}
      loading={loading}
      emptyText={emptyText}
    />
  );
}
