import React, { useState } from "react";
import { Attendance, Game, SubstitutePlayer } from "../../common/interfaces";
import { useGet } from "../../common/hooks";
import { Tag, Tooltip } from "antd";
import Icon from '@ant-design/icons';
import { ensure, formatISODate, getPlayerDisplay } from "../../common/utils";
import _ from "lodash";
import { GameMetaHeader } from "../../common/components/GameMetaHeader";
import { CompactTable } from "../../common/components/CompactTable";
import Spinner from "../../common/components/Spinner";
import {useParams} from "react-router";

export function SubList() {
  const { gameId, teamId } = useParams();

  const { data: game } = useGet<Game>(`/api/games?gameId=${gameId}`);
  const subs = useGet<SubstitutePlayer[]>(
    `/api/subs?gameId=${gameId}&teamId=${teamId}&augmented=true`
  );
  const [filters, setFilters] = useState<
    { [key: string]: string[] } | undefined
  >(undefined);

  // Map of game id to its time as a string.
  const adjacentGameMap: { [key: number]: string } = {};
  (subs.data || []).forEach(s => {
    s.adjacentGames.forEach(g => {
      adjacentGameMap[g.id] = ensure(_.last(formatISODate(g.date).split(" ")));
    });
  });

  const adjacentGameTooltipHtml = (
    <p>
      Indicates the times the player will be at the same rink for another game.
      The time is green if the player responded IN for that game; red if the
      player responded OUT for that game; and gray otherwise.
    </p>
  );

  const columns = [
    {
      key: "player",
      title: "Player",
      render: (text: string, row: SubstitutePlayer) => getPlayerDisplay(row)
    },
    {
      key: "availability",
      title: "Availability",
      render: (text: string, row: SubstitutePlayer) => {
        switch (row.availability) {
          case Attendance.UNRESPONDED:
            return "";
          case Attendance.IN:
            return <Tag color={"cyan"}>Available</Tag>;
          case Attendance.OUT:
            return <Tag color={"red"}>Unavailable</Tag>;
        }
      },
      filters: [
        {
          text: "Available",
          value: Attendance.IN
        }
      ],
      onFilter: (value: any, row: SubstitutePlayer) => {
        const availabilityFilter: string[] = _.get(filters, "availability", []);
        return availabilityFilter.includes(row.availability as string);
      }
    },
    {
      dataIndex: "subbingPrefs.adjacentGames",
      title: (
        <span>
          Nearby Games
          <Tooltip title={adjacentGameTooltipHtml}>
            <Icon
              type={"question-circle"}
              className={"IconPrefix"}
              style={{ paddingLeft: 5 }}
            />
          </Tooltip>
        </span>
      ),
      render: (text: string, row: SubstitutePlayer) => {
        return (
          <span>
            {row.adjacentGames.map(g => (
              <Tag
                color={
                  g.playerAttendance === Attendance.IN
                    ? "cyan"
                    : g.playerAttendance === Attendance.OUT
                    ? "red"
                    : undefined
                }
                key={g.id}
              >
                {adjacentGameMap[g.id]}
              </Tag>
            ))}
          </span>
        );
      },
      filters: _.keys(adjacentGameMap).map(gId => {
        return {
          text: adjacentGameMap[_.toNumber(gId)],
          value: _.toString(gId)
        };
      }),
      onFilter: (value: any, row: SubstitutePlayer) => {
        const adjFilters = (
          _.get(filters, "subbingPrefs.adjacentGames") || []
        ).map(_.toNumber);
        return row.adjacentGames.some(g => adjFilters.includes(g.id));
      }
    },
    {
      key: "contact",
      title: "Contact",
      render: (email: string, row: SubstitutePlayer) => {
        const prefs = ensure(row.subbingPrefs);
        // Show phone over email, even if they've said they're ok with email.
        if (prefs.contactMethods.includes(0)) {
          return prefs.phoneNumber;
        } else {
          return (
            <a
              target={"_blank"}
              href={`mailto:${prefs.email}`}
              rel={"noopener noreferrer"}
            >
              {prefs.email}
            </a>
          );
        }
      }
    },
    {
      dataIndex: "subbingPrefs.positions",
      title: "Positions",
      render: (text: string, row: SubstitutePlayer) => {
        return (
          <span>
            {ensure(row.subbingPrefs).positions.map((pos: number) => (
              <Tag color="blue" key={pos}>
                {getPositionLabel(pos)}
              </Tag>
            ))}
          </span>
        );
      },
      filters: [
        {
          text: "Center",
          value: "0"
        },
        {
          text: "Wing",
          value: "1"
        },
        {
          text: "Defense",
          value: "3"
        }
      ],
      onFilter: (value: any, row: SubstitutePlayer) => {
        const posFilters = (_.get(filters, "subbingPrefs.positions") || []).map(
          _.toNumber
        );
        return ensure(row.subbingPrefs).positions.some(p =>
          posFilters.includes(p)
        );
      }
    },
    {
      dataIndex: "numGamesSubbed",
      title: "Games Subbed"
    }
  ];

  const sortAndFilterSubs = () => {
    return _.orderBy(
      (subs.data || []).filter(s => !!s.subbingPrefs),
      (sub: SubstitutePlayer) => {
        return [
          sub.division,
          sub.availability === Attendance.IN,
          _.sum(
            (sub.adjacentGames || []).map((adj: Game) => {
              switch (adj.playerAttendance) {
                case Attendance.IN:
                  return 3;
                case Attendance.UNRESPONDED:
                  return 2;
                case Attendance.OUT:
                  return 1;
                default:
                  return 0;
              }
            })
          ),
          sub.numGamesSubbed
        ];
      },
      ["desc", "desc", "desc"]
    );
  };

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        {(() => {
          if (!game) {
            return <Spinner size={"default"} />;
          } else {
            return <GameMetaHeader game={game} teamId={_.toNumber(teamId)} />;
          }
        })()}
      </div>
      <CompactTable
        columns={columns}
        data={sortAndFilterSubs()}
        loading={subs.isLoading}
        onChange={(filters: any) => setFilters(filters)}
      />
    </>
  );
}

function getPositionLabel(pos: number): string {
  const map: { [key: number]: string } = {
    0: "C",
    1: "W",
    3: "D"
  };
  return map[pos];
}
