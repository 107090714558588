
export class RosterUrl {
  public static pattern = RosterUrl.createUrl(":gameId", ":teamId");

  static createUrl(gameId: string, teamId: string): string {
    return `/games/${gameId}/roster/${teamId}`;
  }
}

export class ScoresheetUrl {
  public static pattern = ScoresheetUrl.createUrl(":gameId");

  static createUrl(gameId: string): string {
    return `/games/${gameId}`;
  }
}

export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
