export const REGISTRATION_LIST = "/registration";
export const NEW_SEASON = "/newseason";
export const EDIT_SEASONS = "/seasons";
export const MERGE_PLAYERS = "/merge";
export const DIVISION_PLACEMENT = "/divisions";
export const GAMES = "/games";
export const TEAMS = "/teams";

export class PersonDashboardUrl {
  public static pattern = PersonDashboardUrl.createUrl(":personId");

  static createUrl(personId: number | string): string {
    return `/person/${personId}`;
  }
}

export class ScrimmageRosterUrl {
  public static pattern = ScrimmageRosterUrl.createUrl(":scrimmageId");

  static createUrl(scrimmageId: number | string): string {
    return `/scrimmage/${scrimmageId}`;
  }
}
