import { Button, Card, List } from "antd";
import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { DIVISION_PLACEMENT } from "../routes";
import { Link } from "react-router-dom";
import Spinner from "../../common/components/Spinner";

interface SeasonInfo {
  currentSeason: string;
  archiveSeason: string;
  registrationSeason: string;
  registrationSeasonOrder: number;
  gamesAreArchived: boolean;
  playersToEvalCount: number;
  playersWithoutTeamsCount: number;
  teamCountsPerDivision: number;
  registrationsCount: number;
}

export function SeasonSetUp() {
  const seasonInfo = useGet<SeasonInfo>("/api/seasonInfo");
  const archiveStats = usePost({
    uri: "/api/archiveStats",
    deps: [seasonInfo]
  });
  const postRegistrationUpdates = usePost({
    uri: "/api/postRegistrationUpdates"
  });

  if (!seasonInfo.data) {
    return <Spinner size={"large"} />;
  }

  const archiveStatsButton = (
    <Button
      style={{ marginLeft: 10 }}
      onClick={() => archiveStats.send({})}
      loading={archiveStats.isLoading}
      type={"primary"}
    >
      Archive Stats
    </Button>
  );

  const currentSeason = seasonInfo.data.currentSeason;
  const registrationSeason = seasonInfo.data.registrationSeason;
  const registrationLink = seasonInfo.data.registrationSeasonOrder
    ? "https://players.ncwhl.hockey/register/season/" +
      seasonInfo.data.registrationSeasonOrder
    : "";

  const postRegistrationButton = (
    <Button
      style={{ marginLeft: 10 }}
      onClick={() => postRegistrationUpdates.send({})}
      loading={postRegistrationUpdates.isLoading}
      type={"primary"}
      disabled={currentSeason != registrationSeason}
    >
      Make Post-Registration Updates
    </Button>
  );

  return (
    <>
      <Card title={"Season Info"} style={{ width: 500 }}>
        <p>
          <strong>Current season:</strong> {currentSeason}
        </p>
        <p>
          <strong>Registration season:</strong> {registrationSeason}
        </p>
        <p>
          <strong>Registration link:</strong>{" "}
          <a href={registrationLink}>{registrationLink}</a>
        </p>
        <p>
          <strong>Games archived:</strong>{" "}
          {seasonInfo.data.gamesAreArchived ? "Yes" : "No"}
        </p>
        <p>
          <strong>Players needing evaluation:</strong>{" "}
          {seasonInfo.data.playersToEvalCount}
        </p>
        <p>
          <strong>Number of registrations:</strong>{" "}
          {seasonInfo.data.registrationsCount}
        </p>
      </Card>
      <List header={<h3>To-Do</h3>} bordered={true} style={{ marginTop: 10 }}>
        <List.Item extra={archiveStatsButton}>
          Archive the previous season's ({seasonInfo.data.archiveSeason}) stats.
        </List.Item>
        <List.Item extra={postRegistrationButton}>
          Deactivate players who didn't register and set division to unknown for
          players seeking a higher division.
        </List.Item>
        <List.Item>
          When players are placed in a division,{" "}
          <Link to={DIVISION_PLACEMENT}>set their division</Link>.
        </List.Item>
        <List.Item>
          When the number of teams per division is set, create teams.
        </List.Item>
        <List.Item>When drafts are completed, set up team rosters.</List.Item>
        <List.Item>
          The first few weeks of the schedule will come out first, upload those
          games.
        </List.Item>
        <List.Item>
          When the full schedule is released, upload the remaining games.
        </List.Item>
      </List>
    </>
  );
}
