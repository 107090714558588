import React from "react";
import { useGet } from "../../common/hooks";
import {
  PlayerStats,
  DivisionStats,
  TeamStats,
  Team
} from "../../common/interfaces";
import { Divider, Tag, Breadcrumb, Menu } from "antd";
import _ from "lodash";
import Tooltip from "antd/lib/tooltip";
import { useEnsuredUser } from "../../common/contexts";
import { CompactTable } from "../../common/components/CompactTable";
import { ensure, getDivisionName } from "../../common/utils";
import {Link, useParams} from "react-router-dom";
import Spinner from "../../common/components/Spinner";

export function Stats() {
  let { division, season } = useParams();

  const user = useEnsuredUser();
  const team = useGet<Team>(
    user.teamId ? `/api/team?teamId=${user.teamId}` : undefined
  );

  const divisionFallback = team.data
    ? getDivisionName(team.data.division).toLowerCase()
    : "blue";
  division = division || divisionFallback;

  const stats = useGet<DivisionStats>(
    division
      ? `/api/divisionStats?division=${division}&season=${season || ""}`
      : undefined
  );

  if (stats === undefined || stats.data === undefined) {
    return <Spinner size={"large"} />;
  }

  const sortedGoalieStats = _.sortBy(stats.data.goalieStats, g => g.name);
  const sortedTeamStats = _.sortBy(
    stats.data.teamStats.filter(t => t.teamId > 0),
    t => t.teamNumber
  );
  const subStats = stats.data.teamStats.filter(t => t.teamId <= 0).pop();

  let playerStatsColumns = [
    {
      dataIndex: "playerName",
      title: "Name",
      render: (text: string, row: PlayerStats) => {
        if (row.isCaptain) {
          return (
            <span>
              {row.playerName}{" "}
              <Tag
                color="blue"
                style={{
                  lineHeight: "15px",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginBottom: 2.5
                }}
              >
                C
              </Tag>
            </span>
          );
        }
        return text;
      },
      width: 200
    },
    {
      dataIndex: "jerseyNumber",
      title: <Tooltip title="Jersey Number">#</Tooltip>
    },
    {
      dataIndex: "gamesPlayed",
      title: <Tooltip title="Games Played">GP</Tooltip>
    },
    {
      dataIndex: "points",
      title: <Tooltip title="Points">P</Tooltip>
    },
    {
      dataIndex: "goals",
      title: <Tooltip title="Goals">G</Tooltip>
    },
    {
      dataIndex: "assists",
      title: <Tooltip title="Assists">A</Tooltip>
    },
    {
      dataIndex: "penaltyMinutes",
      title: <Tooltip title="Penalties in Minutes">PIM</Tooltip>
    }
  ];

  let teamStatsColumns = [
    {
      dataIndex: "teamName",
      title: "Team"
    },
    {
      dataIndex: "gamesPlayed",
      title: <Tooltip title="Games Played">GP</Tooltip>
    },
    {
      dataIndex: "points",
      title: <Tooltip title="Points">PTS</Tooltip>
    },
    {
      dataIndex: "wins",
      title: <Tooltip title="Wins">W</Tooltip>
    },
    {
      dataIndex: "losses",
      title: <Tooltip title="Losses">L</Tooltip>
    },
    {
      dataIndex: "ties",
      title: <Tooltip title="Ties">T</Tooltip>
    },
    {
      dataIndex: "overtimeLosses",
      title: <Tooltip title="Overtime Losses">OTL</Tooltip>
    },
    {
      dataIndex: "goalsFor",
      title: <Tooltip title="Goals For">GF</Tooltip>
    },
    {
      dataIndex: "goalsAgainst",
      title: <Tooltip title="Goals Against">GA</Tooltip>
    },
    {
      dataIndex: "shotsOnGoal",
      title: <Tooltip title="Shots on Goal">SOG</Tooltip>
    }
  ];

  let goalieStatsColumns = [
    {
      dataIndex: "name",
      title: "Name",
      width: 200
    },
    {
      dataIndex: "jerseyNumber",
      title: <Tooltip title="Jersey Number">#</Tooltip>
    },
    {
      dataIndex: "gamesPlayed",
      title: <Tooltip title="Games Played">GP</Tooltip>
    },
    {
      dataIndex: "wins",
      title: <Tooltip title="Wins">W</Tooltip>
    },
    {
      dataIndex: "losses",
      title: <Tooltip title="Losses">L</Tooltip>
    },
    {
      dataIndex: "ties",
      title: <Tooltip title="Ties">T</Tooltip>
    },
    {
      dataIndex: "overtimeLosses",
      title: <Tooltip title="Overtime Losses">OTL</Tooltip>
    },
    {
      dataIndex: "shotsOnGoal",
      title: <Tooltip title="Shots on Goal">SOG</Tooltip>
    },
    {
      dataIndex: "saves",
      title: <Tooltip title="Saves">S</Tooltip>
    },
    {
      dataIndex: "savePercentage",
      title: <Tooltip title="Save Percentage">SV%</Tooltip>
    },
    {
      dataIndex: "shutouts",
      title: <Tooltip title="Shutouts">SO</Tooltip>
    },
    {
      dataIndex: "goalsAgainstAverage",
      title: <Tooltip title="Goals Against Average">GAA</Tooltip>
    }
  ];

  // Apply the StatsTable class, which sets the padding to 1.
  teamStatsColumns = teamStatsColumns.map(c => ({
    ...c,
    className: "StatsTable"
  }));
  playerStatsColumns = playerStatsColumns.map(c => ({
    ...c,
    className: "StatsTable"
  }));
  goalieStatsColumns = goalieStatsColumns.map(c => ({
    ...c,
    className: "StatsTable"
  }));

  const divisionMenu = (
    <Menu>
      <Menu.Item key={"green"}>
        <Link to={"/stats/green" + (season ? `/${season}` : "")}>Green</Link>
      </Menu.Item>
      <Menu.Item key={"red"}>
        <Link to={"/stats/red" + (season ? `/${season}` : "")}>Red</Link>
      </Menu.Item>
      <Menu.Item key={"maroon"}>
        <Link to={"/stats/maroon" + (season ? `/${season}` : "")}>Maroon</Link>
      </Menu.Item>
      <Menu.Item key={"blue"}>
        <Link to={"/stats/blue" + (season ? `/${season}` : "")}>Blue</Link>
      </Menu.Item>
    </Menu>
  );

  const seasons = _.orderBy(stats.data.availableSeasons, "order", ["desc"]);
  const seasonMenu = (
    <Menu>
      {seasons.map(s => (
        <Menu.Item key={s.path}>
          <Link to={`/stats/${division}/${s.path}`}>{s.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
  const selectedSeasonLabel = season
    ? ensure(seasons.find(s => `${s.path}` === season)).label
    : ensure(seasons[0]).label;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>NCWHL</Breadcrumb.Item>
        <Breadcrumb.Item overlay={divisionMenu}>
          {_.capitalize(division)}
        </Breadcrumb.Item>
        <Breadcrumb.Item overlay={seasonMenu}>
          {selectedSeasonLabel}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Divider>Team Standings</Divider>
      <CompactTable
        data={sortedTeamStats}
        columns={teamStatsColumns}
        loading={stats.isLoading}
        rowKey={"teamId"}
      />
      <Divider>Individual Player Stats</Divider>
      {sortedTeamStats.map((t: TeamStats, i: number) => {
        return (
          <>
            <h2 style={{ marginTop: i === 0 ? 0 : 10 }}>{t.teamName}</h2>
            <CompactTable
              data={_.sortBy(t.playerStats, p => p.playerName)}
              columns={playerStatsColumns}
              rowKey={"personId"}
              loading={stats.isLoading}
            />
          </>
        );
      })}
      {subStats && (
        <>
          <Divider>Subs From Other Divisions</Divider>
          <CompactTable
            data={_.sortBy(subStats.playerStats, p => p.playerName)}
            columns={playerStatsColumns}
            rowKey={"personId"}
            loading={stats.isLoading}
          />
        </>
      )}

      <Divider>Goalies</Divider>
      <CompactTable
        data={sortedGoalieStats}
        columns={goalieStatsColumns}
        loading={stats.isLoading}
        rowKey={"goalieId"}
      />
    </>
  );
}
