import React from "react";
import { Row, Col, Button } from "antd";
import { GoalsEditor } from "../components/GoalsEditor";
import { PenaltiesEditor } from "../components/PenaltiesEditor";
import { useGet } from "../../common/hooks";
import { Game } from "../../common/interfaces";
import Title from "antd/lib/typography/Title";
import {Link, useParams} from "react-router-dom";
import _ from "lodash";
import { RosterUrl } from "../../common/routes";
import { TextWithIconPrefix } from "../../common/components/TextWithIconPrefix";
import { GameMetaHeader } from "../../common/components/GameMetaHeader";
import { GameStaffEditor } from "../components/GameStaffEditor";
import Spinner from "../../common/components/Spinner";
import {EditOutlined} from "@ant-design/icons";

export function Scoresheet() {
  let { gameId } = useParams();
  const { data: game } = useGet<Game>(`/api/games?gameId=${gameId!}`);
  const rowGutter = 24;
  const colSpan = 12;

  if (!game) {
    return <Spinner size={"large"} />;
  }

  const homeRosterLink = (
    <Link to={RosterUrl.createUrl(gameId!, _.toString(game.team1Id))}>
      <Button type={"primary"} style={{ width: 150 }}>
        Edit Home Roster
      </Button>
    </Link>
  );
  const awayRosterLink = (
    <Link to={RosterUrl.createUrl(gameId!, _.toString(game.team2Id))}>
      <Button type={"primary"} style={{ width: 150 }}>
        Edit Away Roster
      </Button>
    </Link>
  );

  return (
    <>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <GameMetaHeader game={game} />
          <TextWithIconPrefix text={homeRosterLink} icon={<EditOutlined />} />
          <TextWithIconPrefix text={awayRosterLink} icon={<EditOutlined />} />
        </Col>
        <Col span={colSpan}>
          <GameStaffEditor gameId={gameId!} />
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetTextRow"}>
        <Col span={colSpan}>
          <Title level={3}>{`Home (${game.team1Name})`}</Title>
        </Col>
        <Col span={colSpan}>
          <Title level={3}>{`Away (${game.team2Name})`}</Title>
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <GoalsEditor gameId={game.id} teamId={game.team1Id} />
        </Col>
        <Col span={colSpan}>
          <GoalsEditor gameId={game.id} teamId={game.team2Id} />
        </Col>
      </Row>
      <Row gutter={rowGutter} className={"ScoresheetDataRow"}>
        <Col span={colSpan}>
          <PenaltiesEditor gameId={game.id} teamId={game.team1Id} />
        </Col>
        <Col span={colSpan}>
          <PenaltiesEditor gameId={game.id} teamId={game.team2Id} />
        </Col>
      </Row>
    </>
  );
}
