import React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export function AddButton(props: { text: string; onClick: () => void }) {
  const { text, onClick } = props;
  return (
    <Button style={{ marginTop: "10px" }} type={"dashed"} onClick={onClick}>
      <PlusCircleOutlined />
      {` ${text}`}
    </Button>
  );
}
