import React, {useState} from "react";
import {GetRequest, usePost} from "../../common/hooks";
import {Button, Form, Input, Modal, TreeSelect} from "antd";
import Icon from '@ant-design/icons';
import {Attendance, EntityWithAttendance, Game, Person, PostRequest, Scrimmage} from "../../common/interfaces";
import Spinner from "./Spinner";
import {formatISODate} from "../utils";
import TextArea from "antd/lib/input/TextArea";
import {TreeNodeNormal} from "antd/lib/tree/Tree";

interface PersonWithAttendance extends Person, EntityWithAttendance {}

export function TeamEmailButton(props: {
  gameResponse?: GetRequest<Game>;
  scrimmageResponse?: GetRequest<Scrimmage>;
  playersResponse: GetRequest<PersonWithAttendance[]>;
  buttonLabel?: string;
  defaultSubject?: string;
  defaultBody?: string;
}) {
  const {
    gameResponse,
    playersResponse,
    scrimmageResponse,
    buttonLabel,
    defaultSubject,
    defaultBody
  } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hideModal = () => setModalVisible(false);

  const emailPostReq = usePost({
    uri: "/api/emailTeam"
  });

  const game = gameResponse || scrimmageResponse;
  if (!(game && game.data) || !playersResponse.data) {
    return <Spinner size={"large"} />;
  }

  const subject = `${defaultSubject} on ${formatISODate(
    game.data.date,
    "MMM dd"
  )}`;

  return (
    <>
      <Button
        key={"showpopup"}
        onClick={() => setModalVisible(true)}
        style={{ marginRight: 10 }}
        type={"primary"}
      >
        <Icon type={"mail"} /> {buttonLabel || "Custom Email"}
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={hideModal}
        footer={null}
        closable={true}
      >
        <EmailForm
          players={playersResponse.data}
          subject={subject}
          body={defaultBody || ""}
          postReq={emailPostReq}
          hideModal={hideModal}
        />
      </Modal>
    </>
  );
}

interface EmailFormProps {
  players: PersonWithAttendance[];
  subject: string;
  body: string;
  postReq: PostRequest;
  hideModal: () => void;
}

interface EmailFormValues {
  players: number[];
  subject: string;
  body: string;
}

function toTreeNode(person: PersonWithAttendance): TreeNodeNormal {
  return {
    title: `${person.firstName} ${person.lastName}`,
    value: person.id,
    key: `${person.id}`
  } as TreeNodeNormal;
}

export function EmailForm(props: EmailFormProps) {
  const { subject, body, players, postReq, hideModal } = props;
  const [form] = Form.useForm();

  const inPlayers = players
    .filter(p => p.attendance === Attendance.IN)
    .map(toTreeNode);
  const outPlayers = players
    .filter(p => p.attendance === Attendance.OUT)
    .map(toTreeNode);
  const unrespondedPlayers = players
    .filter(p => p.attendance === Attendance.UNRESPONDED)
    .map(toTreeNode);

  const treeData = [
    {
      title: "Players who are in",
      value: "inPlayers",
      key: "inPlayers",
      children: inPlayers
    },
    {
      title: "Players who are out",
      value: "outPlayers",
      key: "outPlayers",
      children: outPlayers
    },
    {
      title: "Players not yet responded",
      value: "unrespondedPlayers",
      key: "unrespondedPlayers",
      children: unrespondedPlayers
    }
  ];

  const onSuccess = () => {
    form.resetFields();
    hideModal();
  };

  const onSubmit = (values: EmailFormValues) => {
    form.validateFields()
        .then((validValues: EmailFormValues) => {
          postReq.send(validValues, onSuccess);
        });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 }
  };

  return (
    <Form onFinish={onSubmit} layout={"horizontal"} form={form}>
      <Form.Item
          label={"To"}
          name={"players"}
          rules={[{ required: true }]}
          {...formItemLayout}>
        <TreeSelect
          treeData={treeData}
          treeCheckable={true}
          showCheckedStrategy={"SHOW_CHILD"}
          placeholder="Select who to email"
          style={{ width: "100%" }}></TreeSelect>
      </Form.Item>
      <Form.Item
          label={"Subject"}
          name={"subject"}
          rules={[{ required: true }]}
          initialValue={subject}
          {...formItemLayout}>
        <Input style={{ marginTop: 10 }}></Input>
      </Form.Item>
      <Form.Item
          label={"Body"}
          name={"body"}
          rules={[{ required: true }]}
          initialValue={body}
          {...formItemLayout}>
        <TextArea rows={4} style={{ marginTop: 10, marginBottom: 10 }}></TextArea>
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={postReq.isLoading}
        >
          Send
        </Button>
      </Form.Item>
    </Form>
  );
}
