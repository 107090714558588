import React from "react";
import ReactDOM from 'react-dom/client';
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./common/contexts";


let root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </BrowserRouter>
  </CookiesProvider>
);
