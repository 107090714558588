import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch
} from "react";
import { User } from "./interfaces";
import { ensure } from "./utils";

interface UserContext_ {
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

const UserContext = createContext<UserContext_>({ setUser: () => undefined });

export const useUserContext = () => useContext(UserContext);

export const useEnsuredUser = (): User => {
  const { user: user_ } = useUserContext();
  return ensure(user_);
};

export const UserContextProvider = (props: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
