import React, { ChangeEvent, useState } from "react";
import { Button, Divider, Input, message } from "antd";
import Icon from '@ant-design/icons';
import { useGet, usePost } from "../../common/hooks";
import {Discount, RegistrationPlayer} from "../../common/interfaces";
import Title from "antd/lib/typography/Title";
import { RegistrationCheckout } from "../components/RegistrationCheckout";
import { useUserContext } from "../../common/contexts";
import { ensure } from "../../common/utils";
import { DONATE } from "../routes";
import Spinner from "../../common/components/Spinner";
import {useNavigate, useParams} from "react-router";

interface SeasonInfo {
  seasonName: string;
  seasonId: string;
  autoDiscounts: Discount[];
}

export function SeasonRegistration() {
  const { seasonId } = useParams();
  let navigate = useNavigate();

  const { user } = useUserContext();
  const [email, setEmail] = useState<string>("");

  // player can be any of:
  // * undefined: initial value; we haven't fetched anything from the backend yet
  // * null: there is no player associated with the email provided
  // * RegistrationPlayer: the player associated with the email provided
  const [player, setPlayer] = useState<undefined | null | RegistrationPlayer>(
    undefined
  );
  const emailPostReq = usePost({ uri: "/api/registration/person" });
  const season = useGet<SeasonInfo>(`/api/season?seasonId=${seasonId}`);

  if (!season.data) {
    return <Spinner size={"large"} />;
  }

  // A user is logged and we're not currently in the process of
  // fetching their RegistrationPlayer. Fetch the RegistrationPlayer
  // for this user.
  if (user && player === undefined && !emailPostReq.isLoading) {
    emailPostReq.send(
      { email: user.email, seasonId },
      (data: RegistrationPlayer) => setPlayer(data),
        () => navigate("/")
    );
    return <Spinner size={"large"} />;
  }

  const onPayment = () => {
    message.success(
      `Thanks for registering for the ${ensure(season.data).seasonName}!`,
      4
    );
    navigate(DONATE);
  };

  return (
    <div style={{ maxWidth: 800 }}>
      {(() => {
        if (player === undefined) {
          return (
            <>
              <Title level={2}>{season.data.seasonName} Registration</Title>
              <p>Enter your email address:</p>
              <Input
                style={{ width: 300, marginBottom: 10 }}
                prefix={<Icon type={"mail"} className={"IconPrefix"} />}
                placeholder={"you@gmail.com"}
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                onPressEnter={() =>
                  emailPostReq.send(
                    { email, seasonId },
                    (data: RegistrationPlayer | null) => setPlayer(data)
                  )
                }
              />
              <div>
                <Button
                  type={"primary"}
                  onClick={() =>
                    emailPostReq.send(
                      { email, seasonId },
                      (data: RegistrationPlayer | null) => setPlayer(data)
                    )
                  }
                  loading={emailPostReq.isLoading}
                  disabled={!email.includes("@")}
                >
                  Submit
                </Button>
              </div>
            </>
          );
        } else if (player === null) {
          return (
            <NewPlayerForm
              email={email}
              season={season.data}
              onPayment={onPayment}
            />
          );
        } else {
          return (
            <ExistingPlayerForm
              player={player}
              season={season.data}
              onPayment={onPayment}
            />
          );
        }
      })()}
    </div>
  );
}

function NewPlayerForm(props: {
  email: string;
  season: SeasonInfo;
  onPayment: () => void;
}) {
  const { email, season, onPayment } = props;

  const postReq = usePost({ uri: "/api/registration/create" });

  return (
    <>
      <Title level={2}>{season.seasonName} Registration for New Player</Title>
      <p>
        Registering <strong>new player</strong> for {email}.{" "}
        <strong>If you are already a league member</strong>, make sure to enter
        the same email address you already use for this site (you can start over
        by refreshing this page). Otherwise, please continue the registration
        process below.
      </p>
      <Divider />
      <div>
        <RegistrationCheckout
          postReqIsLoading={postReq.isLoading}
          seasonId={season.seasonId}
          autoDiscounts={season.autoDiscounts}
          onPayment={data => {
            const payload = {
              ...data,
              email,
              seasonId: season.seasonId
            };
            postReq.send(payload, () => onPayment());
          }}
        />
      </div>
    </>
  );
}

function ExistingPlayerForm(props: {
  player: RegistrationPlayer;
  season: SeasonInfo;
  onPayment: () => void;
}) {
  const { player, season, onPayment } = props;
  const postReq = usePost({ uri: "/api/registration/create" });

  return (
    <>
      <Title level={2}>
        {season.seasonName} Registration for {player.firstName}{" "}
        {player.lastName}
      </Title>
      <RegistrationCheckout
        player={player}
        seasonId={season.seasonId}
        autoDiscounts={season.autoDiscounts}
        postReqIsLoading={postReq.isLoading}
        onPayment={data => {
          const payload = {
            ...data,
            email: player.email,
            seasonId: season.seasonId
          };
          postReq.send(payload, () => onPayment());
        }}
      />
    </>
  );
}
